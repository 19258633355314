import { addSpinnerToElement } from "../turbo/frame_control"

$(document).on("turbo:load turbo:frame-render", function () {
  if ($("#devices-tabs").length > 0) {
    if (sessionStorage.getItem("devices-tabs") === null) {
      // Default Tab definieren
      sessionStorage.setItem("devices-tabs", "in-use-tab")
    }

    changeTab()
    listenerForTabChange()

    setTimeout(function () {
      if ($("#unassigned-tab.active").length > 0) {
        loadUnassignedDevices()
      }
    }, 1000) // die Klass active wird erst nach ca 1 Sekunde gesetzt
  }
})

function listenerForTabChange() {
  $("ul.nav-tabs a.nav-link").on("click", function () {
    const navName = $(this).parents("ul:first").attr("id")
    sessionStorage.setItem(navName, $(this).attr("id"))
  })

  $("#devices-tabs #unassigned-tab").on("click", function () {
    loadUnassignedDevices()
  })
}

function changeTab() {
  const previousTab = sessionStorage.getItem("devices-tabs")
  $("#" + previousTab).tab("show")
}

function loadUnassignedDevices() {
  const oxocareModule = window.location.pathname.split("/")[1]
  const filterParams = new URLSearchParams(window.location.search).toString()
  const unassignedDeviceUrl = "/unassigned_devices?oxocare_module=" + oxocareModule + "&" + filterParams
  const unassignedTab = document.getElementById("unassigned")
  addSpinnerToElement(unassignedTab)

  $.ajax({
    url: unassignedDeviceUrl,
    success: function (html) {
      unassignedTab.innerHTML = html
    },
  })

  $(document).ajaxStop(function (event, request, settings) {
    $("#unassigned.asynchron").find(".data-table-devices").addClass("new-data-table-devices")

    if (!$(".new-data-table-devices").hasClass("dataTable")) {
      // DataTable für sortierbare Device Tabelle
      // Sortierung: 1Column
      $(".new-data-table-devices")
        .DataTable({
          paging: false,
          searching: false,
          info: false,
          order: [[0, "asc"]],
          columnDefs: [{ targets: "date_col", type: "date-eu" }],
        })
        .columns()
        .iterator("column", function (ctx, idx) {
          $(this.column(idx).header()).append('<span class="sort-icon"/>')
        })
    }
  })
}
