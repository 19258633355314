import { DateTime } from "luxon"

import { createChart, updateChart, generalEventLineChartOptions, generalEventDoughnutChartOptions } from "../global/chart_functions"
import { animateCountUp } from "../global/count_up"
import { setDatepickerFields } from "../global/bootstrap_datepicker"

let allStatisticCharts = []

// Damit beim Browser-Zurück Navigieren die Charts neu initialisiert werden
$(document).on("turbo:before-cache", function () {
  if ($("#statistics_page").length > 0) {
    allStatisticCharts.forEach(function (chart) {
      chart.destroy()
    })
    allStatisticCharts = []
  }
})

$(document).on("turbo:load", async function () {
  if ($("#statistics_page").length > 0) {
    // JSON Daten holen
    async function getStatisticData(whichData, timestampStart, timestampEnd) {
      const response = await fetch("/statistics/customer.json?which_data=" + whichData + "&timestamp_start=" + timestampStart + "&timestamp_end=" + timestampEnd)
      const data = await response.json()

      return data
    }

    //Sämtliche Daten updaten
    async function updateStatisticData(timestampStart, timestampEnd) {
      // Update Datepicker Felder
      setDatepickerFields(timestampStart, timestampEnd)

      // Event Zahlen anzeigen
      displayEventNumbers(await getStatisticData("statistics_page_numbers", timestampStart, timestampEnd))

      // Update Charts
      for (let i = 0; i < allStatisticCharts.length; i++) {
        chartData = await getStatisticData(allStatisticCharts[i].canvas.id, timestampStart, timestampEnd)
        updateChart(allStatisticCharts[i], chartData.x, chartData.y)
      }
    }

    // Event Zahlen darstellen
    function displayEventNumbers(newAmounts, countUp = "") {
      for (let i = 0; i < displayedNumbers.length; i++) {
        const idFromNumber = displayedNumbers[i][0].id
        const newAmount = String(newAmounts[idFromNumber]).replace(/(.)(?=(\d{3})+$)/g, "$1'")
        displayedNumbers[i].find("h3").html(newAmount)
        if (countUp) {
          animateCountUp(displayedNumbers[i].find("h3")[0]) // CountUp Animation
        }
      }
    }

    // Chart Daten zusammenstellen
    function constructChartJSData(data, colors, gradient) {
      return {
        labels: data.x,
        datasets: [
          {
            label: false,
            data: data.y,
            lineTension: 0.4,
            backgroundColor: colors.backgroundColors,
            borderColor: colors.borderColors,
            borderWidth: 2,
            fill: true,
            gradient: gradient,
          },
        ],
      }
    }

    // Chart Farben
    function lineColors() {
      return {
        backgroundColors: ["rgba(54, 162, 235, 0.2)"],
        borderColors: ["rgba(54, 162, 235, 1)"],
      }
    }
    function doughnutColors() {
      return {
        backgroundColors: ["rgba(11, 72, 255, 0.7)", "rgba(10, 194, 255, 0.7)", "rgba(10, 255, 255, 0.7)", "rgba(10, 255, 10, 0.7)", "rgba(255, 255, 10, 0.7)", "rgba(255, 133, 10, 0.7)"],
        borderColors: ["rgba(11, 72, 255, 1)", "rgba(10, 194, 255, 1)", "rgba(10, 255, 255, 1)", "rgba(10, 255, 10, 1)", "rgba(255, 255, 10, 1)", "rgba(255, 133, 10, 1)"],
      }
    }

    // Zeitspanne der Daten beim ersten Aufruf bestimmen
    const timeNow = DateTime.now()
    let timestampStart = timeNow.minus({ weeks: 1 }).startOf("day").toSeconds()
    let timestampEnd = timeNow.toSeconds()

    // Welche Zeitraum ist gerade ausgewählt (Jahr Monat Woche Tag)
    let timePeriod = { weeks: 1 }

    const chartsToDisplay = [
      { id: "amount_events_per_hour", type: "line" },
      { id: "amount_events_per_weekday", type: "bar" },
    ]

    // Charts Initial Erstellen
    async function initAllCharts() {
      const chartData = await getStatisticData("statistics_page_charts", timestampStart, timestampEnd)

      chartsToDisplay.forEach(async function (chart) {
        let colors
        let chartJSData
        let chartJSOptions
        if (chart.type == "line" || chart.type == "bar") {
          colors = lineColors()
          chartJSData = constructChartJSData(chartData[chart.id], colors, true)
          chartJSOptions = generalEventLineChartOptions()
        } else {
          colors = doughnutColors()
          chartJSData = constructChartJSData(chartData[chart.id], colors, false)
          chartJSOptions = generalEventDoughnutChartOptions()
        }

        const createdChart = await createChart(chart.type, chart.id, { data: chartJSData, options: chartJSOptions })
        allStatisticCharts.push(createdChart)
      })
    }
    initAllCharts()

    // Event Zahlen anzeigen
    const displayedNumbers = [$("#event_amount"), $("#average_events_per_hour"), $("#average_events_per_day")]
    displayEventNumbers(await getStatisticData("statistics_page_numbers", timestampStart, timestampEnd), true)
    setDatepickerFields(timestampStart, timestampEnd)

    // Eventlistener für Zeitspanne
    $("#statistic-show-total").on("click", async function () {
      $(this).addClass("active").siblings().removeClass("active")

      timePeriod = { years: 1 }
      timestampStart = DateTime.local(2018, 1, 1).toSeconds()
      timestampEnd = DateTime.now().toSeconds()
      updateStatisticData(timestampStart, timestampEnd)
    })

    $("#statistic-show-year").on("click", async function () {
      $(this).addClass("active").siblings().removeClass("active")

      timePeriod = { years: 1 }
      timestampStart = DateTime.local().minus({ year: 1 }).startOf("day").toSeconds()
      timestampEnd = DateTime.now().toSeconds()
      updateStatisticData(timestampStart, timestampEnd)
    })

    $("#statistic-show-month").on("click", async function () {
      $(this).addClass("active").siblings().removeClass("active")

      timePeriod = { months: 1 }
      timestampStart = DateTime.local().minus({ month: 1 }).startOf("day").toSeconds()
      timestampEnd = DateTime.now().toSeconds()
      updateStatisticData(timestampStart, timestampEnd)
    })

    $("#statistic-show-week").on("click", async function () {
      $(this).addClass("active").siblings().removeClass("active")

      timePeriod = { weeks: 1 }
      timestampStart = DateTime.local().minus({ weeks: 1 }).startOf("day").toSeconds()
      timestampEnd = DateTime.now().toSeconds()
      updateStatisticData(timestampStart, timestampEnd)
    })

    $("#statistic-show-today").on("click", async function () {
      $(this).addClass("active").siblings().removeClass("active")

      timestampStart = DateTime.local().startOf("day").toSeconds()
      timestampEnd = DateTime.now().toSeconds()
      updateStatisticData(timestampStart, timestampEnd)
    })

    // Eventlistener für Pfeile
    $("#statistic-arrow-back").on("click", async function () {
      timestampStart = DateTime.fromSeconds(timestampStart).minus(timePeriod).startOf("day").toSeconds()
      timestampEnd = DateTime.fromSeconds(timestampEnd).minus(timePeriod).endOf("day").toSeconds()
      updateStatisticData(timestampStart, timestampEnd)
    })
    $("#statistic-arrow-forth").on("click", async function () {
      timestampStart = DateTime.fromSeconds(timestampStart).plus(timePeriod).startOf("day").toSeconds()
      timestampEnd = DateTime.fromSeconds(timestampEnd).plus(timePeriod).endOf("day").toSeconds()

      // Man darf nicht in die Zukunft blättern können
      if (timestampStart > timeNow.toSeconds()) {
        timestampStart = timeNow.toSeconds()
      }
      if (timestampEnd > timeNow.toSeconds()) {
        timestampEnd = timeNow.toSeconds()
      }

      updateStatisticData(timestampStart, timestampEnd)
    })

    // Datepicker 'changeDate' event triggered auch bei input-Field änderungen von JS
    // Nur wenn Datepicker auswählt wird, soll 'changeDate' event triggern
    let datepicker_was_changed = false
    $(".input-daterange").on("focus", function (e) {
      datepicker_was_changed = true
    })
    $(".input-daterange").on("changeDate", function (e) {
      if (datepicker_was_changed) {
        datepicker_was_changed = false
        $("#statistic-show-today").removeClass("active").siblings().removeClass("active")
        updateStatisticData(DateTime.fromFormat($("#datepicker-start").val(), "dd.MM.yyyy").startOf("day").toSeconds(), DateTime.fromFormat($("#datepicker-end").val(), "dd.MM.yyyy").endOf("day").toSeconds())
      }
    })
  }
})
