import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dom-manipulations"
export default class extends Controller {
  static targets = ["element", "destroyField", "icon"]
  static values = {
    autoRemove: Boolean,
  }

  connect() {
    this.target = this.hasElementTarget ? this.elementTarget : this.element

    if (this.autoRemoveValue) {
      this.removeElement()
    }
  }

  removeElement() {
    this.target.remove()
  }

  // Entfernt das Element mit einer Fade-Away-Animation
  removeElementWithAnimation() {
    setTimeout(() => {
      this.target.classList.add("fade-away")
    }, 500)

    setTimeout(() => {
      this.target.remove()
    }, 2000)
  }

  hideElement() {
    this.elementTargets.forEach((element) => element.classList.add("d-none"))
  }

  showElement() {
    this.elementTargets.forEach((element) => element.classList.remove("d-none"))
  }

  setDestroyField() {
    this.destroyFieldTargets.forEach((element) => (element.value = true))
  }

  addClass(event) {
    this.elementTargets.forEach((element) => element.classList.add(event.params.class))
  }

  setValue(event) {
    const value = event.params.value || ""
    this.elementTargets.forEach((element) => (element.value = value))
  }

  toggleCSSClass({ params: { cssClass } }) {
    this.elementTargets.forEach((element) => element.classList.toggle(cssClass))
  }

  toggleIcon({ params: { iconClass } }) {
    this.iconTargets.forEach((element) => element.classList.toggle(iconClass))
  }
}
