import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="partials--device-usages"
export default class extends Controller {
  static targets = ["usageDropdown"]

  connect() {
    if (this.hasUsageDropdownTarget) {
      $(this.usageDropdownTarget).on("select2:select", (e) => {
        this.updateUsageSettingsFrame()
      })
    }
  }

  updateUsageSettingsFrame() {
    const url = new URL(window.location.href)
    url.searchParams.append("usage_id", this.usageDropdownTarget.value)
    Turbo.visit(url, { frame: "usage-settings" })
  }
}
