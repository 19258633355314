$(document).on("turbo:load", function () {
  $("#zone_zone_type").on("change", function () {
    if ($(this).val() === "runaway_protection") {
      $("form").removeClass("narrow")
      $("#zone-main-form").addClass("col-lg-9")
      $("#zone-devices").removeClass("d-none")
      $("#zone_name").css("width", "73%")
    } else {
      $("form").addClass("narrow")
      $("#zone-main-form").removeClass("col-lg-9").addClass("col")
      $("#zone-devices").addClass("d-none")
      $('input[name="zone[device_ids][]"]').prop("checked", false)
    }
  })
})
