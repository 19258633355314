$(document).on("turbo:load", function () {
  $("[data-play]").on("click", function (event) {
    event.preventDefault()
    var audioId = $(this).data("play")
    var audio = document.getElementById(audioId)
    audio.play()
  })

  var audioSelector = $(".change-audio")

  audioSelector.on("change", function () {
    var select = $(this)
    var audio = select.parent().find("audio")
    audio.find("source:first").attr("src", "/sounds/" + select.val() + ".mp3")
    audio[0].load()
  })

  // Trigger change on load to set right audio source
  audioSelector.trigger("change")
})
