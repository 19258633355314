import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon"

// Connects to data-controller="turbo-helper"
export default class extends Controller {
  static values = {
    renderedAt: Number,
    refreshDebounceInSeconds: { type: Number, default: 10 },
  }

  clearCache() {
    // Turbo Cache leeren (wichtig für z.B. die Navigation)
    Turbo.cache.clear()

    // Alle data Attribute `data-turbo-permanent` entfernen, damit diese beim nächsten Laden neu initialisiert werden
    document.querySelectorAll("[data-turbo-permanent]").forEach((element) => {
      element.removeAttribute("data-turbo-permanent")
    })
  }

  // Mittels Turbo Morph die Seite neu laden
  refresh() {
    Turbo.session.refresh(location.href)
  }

  // Mittels Turbo Visit die Seite neu laden
  reload() {
    Turbo.visit(location.href, { action: "replace" })
  }

  // Bestimmte Seiten können aufgrund unterschiedliche Ereignisse neu geladen werden:
  // Das Dashboard z.B. kann aufgrund folgender Ereignisse neu geladen werden:
  // - Event Erstellung -/ Veränderung broadcastet einen refresh mittels `broadcast_refresh_to "dashboard"`
  // - Mobile App kommt in den Vordergrund
  // - Mobile App bekommt eine Push Notification (Hier wollen wir ebenfalls die Seite neu laden, um die aktuellen Informationen anzuzeigen unabhängig von der Websocket Verbindung)
  // Damit wir die Seite nicht unnötig oft hintereinander laden, stellen wir hier sicher, dass wir nur neuladen wenn der Content älter als 10 Sekunden ist.
  maybeRefresh() {
    const currentTime = DateTime.now().toSeconds()
    const shouldRefresh = currentTime - this.renderedAtValue > this.refreshDebounceInSecondsValue

    if (shouldRefresh) {
      Turbo.session.refresh(location.href)
    }
  }

  fullPageReload() {
    window.location.reload()
  }
}
