import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-progress-bar"
export default class extends Controller {
  static targets = ["bar"]
  static values = {
    durationSeconds: { type: Number, default: 10 },
  }

  connect() {
    this.startAnimation()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startAnimation() {
    this.barTarget.classList.add("progress-bar-animated")
    this.barTarget.classList.add("progress-bar-striped")
    this.interval = setInterval(this.animateProgress.bind(this), 100)
  }

  stopAnimation() {
    clearInterval(this.interval)
    this.barTarget.classList.remove("progress-bar-animated")
    this.barTarget.classList.remove("progress-bar-striped")
  }

  animateProgress() {
    const speed = 100 / this.durationSecondsValue
    const progress = parseInt(this.barTarget.style.width) || 0
    const increment = speed / this.durationSecondsValue

    if (progress < 100) {
      this.barTarget.style.width = `${progress + increment}%`
    } else {
      this.stopAnimation()
    }
  }
}
