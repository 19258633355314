import { Controller } from "@hotwired/stimulus"
import { parseJSON } from "../../global/utils"

// Connects to data-controller="partials--sign-in"
export default class extends Controller {
  static targets = ["form"]

  signInThroughQRCode({ detail: { content } }) {
    let { data, cornerPoints } = content
    data = parseJSON(data)

    if (data) {
      const { token, env } = data

      if (env !== this.currentEnvironment) {
        alert(`Ungültig! Gescannter QR-Code stammt von ${env} und nicht von ${this.currentEnvironment}`)
      } else if (token) {
        this.formTarget.querySelector('input[name="sign_in_token"]').value = token
        this.formTarget.requestSubmit()
        this.stopScanning()
      }
    }
  }

  stopScanning() {
    window.dispatchEvent(new Event("stop-scanning"))
  }

  get currentEnvironment() {
    return document.querySelector("meta[name='environment']").content
  }
}
