import { Controller } from "@hotwired/stimulus"
import "datatables.net-bs4"
import { dataTableWithDateTimeSort } from "../global/date_eu"

// Connects to data-controller="data-tables"
export default class extends Controller {
  connect() {
    dataTableWithDateTimeSort()

    this.defaultOrder = [[0, "desc"]]
    // defaultOrder kann überschrieben werden: data-default-order='[[ 2, "asc" ]]'
    if (this.element.dataset.defaultOrder) {
      this.defaultOrder = JSON.parse(this.element.dataset.defaultOrder)
    }

    // führt .DataTable({}) nur aus, wenn es nicht bereits ausgeführt wurde.
    if (!$(".data-table").hasClass("dataTable")) {
      this.generalDataTable()
    }

    if (!$(".data-table-devices").hasClass("dataTable")) {
      this.dataTableForDevices()
    }

    if (!$(".data-table-events").hasClass("dataTable")) {
      this.dataTableForEvents()
    }

    if (!$(".data-table-users").hasClass("dataTable")) {
      this.dataTableForUsers()
    }

    if (!$(".data-table-mobile-users").hasClass("dataTable")) {
      this.dataTableForMobileUsers()
    }
  }

  // General DataTable für sortierbare Tabelle
  generalDataTable() {
    $(".data-table")
      .DataTable({
        paging: false,
        searching: false,
        info: false,
        order: this.defaultOrder,
        columnDefs: [
          { targets: "nosort", orderable: false },
          { targets: "date_col", type: "date-eu" },
        ],
      })
      .columns()
      .iterator("column", function (ctx, idx) {
        $(this.column(idx).header()).append('<span class="sort-icon"/>')
      })
  }

  // Sortierung: 4te Column
  dataTableForDevices() {
    $(".data-table-devices")
      .DataTable({
        paging: false,
        searching: false,
        info: false,
        order: [[3, "desc"]],
        columnDefs: [{ targets: "date_col", type: "date-eu" }],
      })
      .columns()
      .iterator("column", function (ctx, idx) {
        $(this.column(idx).header()).append('<span class="sort-icon"/>')
      })
  }

  // Sortierung: 1te Column
  // Kann nach "Bell" Icons sortiert werden
  dataTableForEvents() {
    $(".data-table-events")
      .DataTable({
        paging: false,
        searching: false,
        info: false,
        order: [[3, "desc"]],
        columnDefs: [
          { targets: "nosort", orderable: false },
          { targets: "date_col", type: "date-eu" },
          {
            targets: [0],
            render: function (data, type, full, meta) {
              if (type == "sort") {
                return $(data).hasClass("fa-bell") ? 1 : 0
              } else {
                return data
              }
            },
          },
        ],
      })
      .columns()
      .iterator("column", function (ctx, idx) {
        $(this.column(idx).header()).append('<span class="sort-icon"/>')
      })
  }

  // Sortierung: 1te Column
  dataTableForMobileUsers() {
    $(".data-table-mobile-users").DataTable({
      paging: false,
      searching: false,
      info: false,
      order: [[1, "desc"]],
    })
  }

  // Sortierung: 1te Column
  // Kann nach "Times & User" Icons sortiert werden
  dataTableForUsers() {
    $(".data-table-users").DataTable({
      paging: false,
      searching: false,
      info: false,
      order: [[1, "desc"]],
    })
  }
}
