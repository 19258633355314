import FingerprintJS from "@fingerprintjs/fingerprintjs"
const fpPromise = FingerprintJS.load()

async function getBrowserFingerprint() {
  const fp = await fpPromise
  const result = await fp.get()
  return result.visitorId
}

export { getBrowserFingerprint }
