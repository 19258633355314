import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["formRow", "formElement", "textField", "searchField", "dropdownMenu"]
  static values = {
    paramName: String,
    filterType: { type: String, default: "checkbox" }, // "checkbox", "radio", "text" oder "toggle"
  }

  connect() {
    // Save the TurboFrame in which this controller is embedded
    // Later, when a request is made, we can update only this frame instead of the whole page
    this.parentTurboFrame = this.element.closest("turbo-frame")
  }

  selectAll() {
    this.formElementTargets.forEach((checkbox) => (checkbox.checked = true))
  }

  deselectAll() {
    this.formElementTargets.forEach((checkbox) => (checkbox.checked = false))
  }

  clearInput() {
    this.textFieldTarget.value = null
  }

  toggleSearchField() {
    this.searchFieldTarget.classList.toggle("d-none")
    this.searchFieldTarget.focus()
  }

  setSearchFieldFocus() {
    this.searchFieldTarget.classList.remove("d-none")
    this.searchFieldTarget.focus()
  }

  updateResourceView(event) {
    const searchValue = event.target.value.toLowerCase()
    this.formRowTargets.forEach((row) => {
      const label = row.querySelector("label").textContent.toLowerCase()
      row.style.display = label.includes(searchValue) ? "block" : "none"
    })
  }

  setFocusDateField() {
    this.textFieldTarget.focus()
  }

  applyFilter() {
    Turbo.visit(this.updatedUrl, { frame: this.parentTurboFrame.id })

    // Manually update the browser history
    // When issue https://github.com/hotwired/turbo/issues/489 is resolved, this can be removed
    // When the issue is resolved, we can simply call Turbo.visit(this.newUrl, { frame: this.parentTurboFrame.id, action: "advance" });
    window.history.pushState(history.state, "", this.updatedUrl)
  }

  closeDropdownMenu() {
    if (!this.hasDropdownMenuTarget) return
    this.dropdownMenu.hide()
  }

  get dropdownMenu() {
    return bootstrap.Dropdown.getOrCreateInstance(this.dropdownMenuTarget)
  }

  get updatedUrl() {
    this.params = new URLSearchParams(this.currentURL.search)
    this.params.set(this.paramNameValue, this.selectedValues)
    return `${window.location.pathname}?${this.params.toString()}`
  }

  get selectedValues() {
    switch (this.filterTypeValue) {
      case "checkbox":
      case "radio":
        return this.formElementTargets.filter((formElement) => formElement.checked).map((formElement) => formElement.value)
      case "text":
        return this.textFieldTarget.value
      case "toggle":
        return this.params.get(this.paramNameValue) === "true" ? "false" : "true"
      default:
        return ""
    }
  }

  get currentURL() {
    return new URL(window.location)
  }
}
