import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging"
import { getBrowserFingerprint } from "../global/browser_fingerprint.js"
import { DateTime } from "luxon"

// Dies sind eindeutige aber nicht geheime Informationen
// deswegen ist es in Ordnung, diese an den Browser zu übermitteln
const firebaseConfig = {
  apiKey: "AIzaSyDMtQq7iZalTu4I1KMYGs0XdGho36rckbc",
  appId: "1:471028162147:android:a1996e87285d1940",
  authDomain: "oxocare-196707.firebaseapp.com",
  databaseURL: "https://oxocare-196707.firebaseio.com",
  messagingSenderId: "471028162147",
  projectId: "oxocare-196707",
  storageBucket: "oxocare-196707.appspot.com",
}

async function sendTokenToAppServer(token) {
  const browserFingerprint = await getBrowserFingerprint()
  $.ajax({
    method: "POST",
    url: "/push_notification_registrations",
    data: {
      push_notification_registration: {
        token: token,
        unique_device_id: browserFingerprint,
        device_type: "web",
      },
    },
  })
}

async function deletePushNotification() {
  const browserFingerprint = await getBrowserFingerprint()
  $.ajax({
    method: "DELETE",
    url: "/push_notification_registrations/" + browserFingerprint,
    data: {
      unique_device_id: browserFingerprint,
    },
  })
}

function tokenUpdateIsNecessary() {
  // Wenn letztes tokenUpdate älter als 24h ist
  return Number(localStorage.getItem("webPushLastUpdate")) < DateTime.now().toSeconds() - 60 * 60 * 24
}

function startFirebaseMessaging() {
  const app = initializeApp(firebaseConfig)
  const messaging = getMessaging(app)
  getToken(messaging, {
    vapidKey: "BOMfhNY1EbyigXJIbtc2XSUd0ke5MhuKWDjh8r2ZXtWUnwTOBOzhQkYo4Rj9fLKP7pMaQwGDjPI8tt9gyetZCCk",
  })
    .then((token) => {
      if (token && tokenUpdateIsNecessary() == true) {
        sendTokenToAppServer(token)

        $("#receive-push-notifications").prop("checked", true)
        localStorage.setItem("webPushIsEnabled", "true")
        localStorage.setItem("webPushLastUpdate", DateTime.now().toSeconds())
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err)
    })

  onMessage(function (payload) {
    if (payload.data["type"] === "event") {
      $("#event-received-indicator").addClass("d-lg-block")
    }
  })
}

function cannotSendPushNotifactionAlert() {
  alert("Wir können keine PushNotifications versenden! Es wurden keine Berrechtigung für PushNotifications erteilt.")
}

async function checkNotificationPermission() {
  const browserIsSupported = await isSupported()
  let readyToSendNotifiaction = false

  if (browserIsSupported === true) {
    if (Notification.permission === "granted") {
      readyToSendNotifiaction = true
    } else if (Notification.permission !== "denied") {
      // Benutzer nach Berrechtigung für PushNotifications fragen
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          readyToSendNotifiaction = true
        } else {
          cannotSendPushNotifactionAlert()
        }
      })
    } else {
      cannotSendPushNotifactionAlert()
    }
  }
  return readyToSendNotifiaction
}

$(document).on("turbo:load", async function () {
  const webPushNotifactonCheckbox = $("#receive-push-notifications")
  if (localStorage.getItem("webPushIsEnabled") === "true") {
    webPushNotifactonCheckbox.prop("checked", true)
    startFirebaseMessaging()
  }

  webPushNotifactonCheckbox.on("change", async function () {
    const webPushIsEnabled = !!$(this).is(":checked")
    const readyToSendNotifiaction = await checkNotificationPermission()

    if (webPushIsEnabled && readyToSendNotifiaction) {
      startFirebaseMessaging()
    } else {
      localStorage.setItem("webPushIsEnabled", "false")
      localStorage.setItem("webPushLastUpdate", "0")
      webPushNotifactonCheckbox.prop("checked", false)
      deletePushNotification()
    }
  })
})
