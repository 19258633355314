import "bootstrap-datepicker"

!(function (a) {
  a.fn.datepicker.dates.de = {
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: "Heute",
    monthsTitle: "Monate",
    clear: "Löschen",
    weekStart: 1,
    format: "dd.mm.yyyy",
  }
})(jQuery)

$(document).on("turbo:load", function () {
  $(".input-daterange")
    .datepicker({
      endDate: "0d",
      autoclose: true,
      format: "dd.mm.yyyy",
      todayBtn: "linked",
      language: "de",
      todayHighlight: true,
    })
    .on("show", function (e) {
      if (e.target.id == "time-start" || e.target.id == "time-end") {
        $(".datepicker").hide() // Bei Time-Inputs söll der Datepicker nicht angezeigt werden
      }
    })
})
