/**
 * Similar to the Date (dd/mm/YY) data sorting plug-in, this plug-in offers
 * additional  flexibility with support for spaces between the values and
 * either . or / notation for the separators.
 *
 * Please note that this plug-in is **deprecated*. The
 * [datetime](//datatables.net/blog/2014-12-18) plug-in provides enhanced
 * functionality and flexibility.
 *
 *  @name Date (dd . mm[ . YYYY])
 *  @summary Sort dates in the format `dd/mm/YY[YY]` (with optional spaces)
 *  @author [Robert Sedovšek](http://galjot.si/)
 *
 *  @example
 *    $('#example').dataTable( {
 *       columnDefs: [
 *         { type: 'date-eu', targets: 0 }
 *       ]
 *    } );
 */

function dataTableWithDateTimeSort() {
  jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "date-eu-pre": function (date) {
      if (!date || date == '<i class="fas fa-minus" aria-hidden="true"></i>') {
        return 0
      }

      let eu_time
      let eu_date = date.split(/[\.\-\/]/)
      let year, month, day, hour, minute, second

      if (!(eu_date[2] == undefined)) {
        let eu_year_time = eu_date[2].split(" ")
        if (!(eu_year_time[1] == undefined)) {
          eu_time = eu_year_time[1].split(":")
        }
      }

      /*year (optional)*/
      if (eu_date[2]) {
        year = eu_date[2].split(" ")[0]
      } else {
        year = 0
      }

      /*month*/
      month = eu_date[1]
      if (month.length == 1) {
        month = 0 + month
      }

      /*day*/
      day = eu_date[0]
      if (day.length == 1) {
        day = 0 + day
      }

      /* If a Time is present*/
      if (!(eu_time == undefined)) {
        /*hour*/
        hour = eu_time[0]
        if (hour.length == 1) {
          hour = 0 + hour
        }

        /*minute*/
        minute = eu_time[1]
        if (minute.length == 1) {
          minute = 0 + minute
        }

        /*second*/
        second = eu_time[2]
        if (second == undefined) {
          second = 0
        } else if (second.length == 1) {
          second = 0 + second
        }
      } else {
        hour = 0
        minute = 0
        second = 0
      }

      return (year + month + day + hour + minute + second) * 1
    },

    "date-eu-asc": function (a, b) {
      return a < b ? -1 : a > b ? 1 : 0
    },

    "date-eu-desc": function (a, b) {
      return a < b ? 1 : a > b ? -1 : 0
    },
  })
}

export { dataTableWithDateTimeSort }
