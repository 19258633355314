import { Controller } from "@hotwired/stimulus"
import { createChart } from "../global/chart_functions"
import { DateTime } from "luxon"

// Connects to data-controller="sensor-chart"
export default class extends Controller {
  connect() {
    this.chart?.destroy()

    this.deviceId = this.element.dataset.deviceId

    // Standard Zeitraum
    this.currentPeriod = { hours: 24 }
    this.currentTimestampStart = DateTime.local().minus({ hours: 24 }).toSeconds()
    this.currentTimestampEnd = DateTime.now().toSeconds()

    // Chart initial laden
    this.initializeChart().then((chart) => {
      this.chart = chart
    })
  }

  disconnect() {
    this.chart?.destroy()
  }

  resetChartZoom() {
    this.chart.resetZoom()
    this.hideResetZoomButton()
  }

  async initializeChart() {
    // Sämtliche Sensor Daten holen
    const jsonData = await this.getBatteryData(this.currentTimestampStart, this.currentTimestampEnd)

    // Chart Optionen generieren und Chart anzeigen
    const chartData = this.deviceBatteryChartData(jsonData)
    const chartOptions = this.deviceBatteryChartOptions(jsonData)

    if (this.showDetails) {
      // Sensor Daten Tabelle rendern, wenn Sensor#show angezeigt wird
      this.displayAdditionalSensorInfo("sensor_events")
    }

    return createChart("line", this.deviceId, { data: chartData, options: chartOptions, jsonData: jsonData })
  }

  async getBatteryData(start = this.currentTimestampStart, end = this.currentTimestampEnd) {
    const response = await fetch(`/device_battery_stats.json?device_id=${this.deviceId}&timestamp_start=${start}&timestamp_end=${end}`)
    const data = await response.json()
    return data
  }

  // Ober -/ Untergrenze von Chart bestimmen
  calcMinMaxChartBorder(data) {
    let chartMin = Math.min(...data.battery_data.lora_values, ...data.battery_data.device_values)
    let chartMax = Math.max(...data.battery_data.lora_values, ...data.battery_data.device_values)

    // round up to next 50
    chartMax = Math.ceil(chartMax / 50) * 50

    return { chartMin: Math.round(chartMin), chartMax: Math.round(chartMax) }
  }

  batteryChangeLines(data, chartMin, chartMax) {
    let lines = {}
    data.battery_changes.forEach((changeDate, index) => {
      const lineConfiguration = {
        type: "line",
        borderColor: "rgba(225, 56, 0, 1)",
        borderWidth: 2,
        borderDash: [0],
        label: {
          enabled: true,
          backgroundColor: "rgba(225, 56, 0, 0.8)",
          color: "white",
          content: "Batteriewechsel",
          font: {
            size: 10,
          },
        },
        yMax: chartMax,
        yMin: chartMin,
        yScaleID: "y",
        xMax: changeDate,
        xMin: changeDate,
        xScaleID: "x",
      }
      lines[`line${index}`] = lineConfiguration
    })

    return lines
  }

  movingAverage(n, keys, values, debug = false) {
    let result = []
    // reverse arrays
    // values = values.reverse();
    // keys = keys.reverse();
    while (values.length > n) {
      // Erster Wert aus Arrays nehmen
      const currentValue = values[0]
      const currentLabel = keys[0]
      const currentDate = DateTime.fromISO(currentLabel)

      // Erster Wert aus Arrays entfernen
      values = values.slice(1)
      keys = keys.slice(1)

      // Letzter Wert aus Array nehmen
      const lastValue = result[result.length - 1]?.data || 0
      const lastLabel = result[result.length - 1]?.label
      const lastDate = DateTime.fromISO(lastLabel)

      // Zeitunterschied zwischen letztem und aktuellem Wert
      const timeDiff = lastDate.diff(currentDate, "hours").hours

      // Wenn der Unterschied innerhalb 12 Stunden kleiner wie 30% ist, dann wird der Wert mit einberechnet
      // currentValue = 60, lastValue = 100, zeitUnterschied = 12h
      // if (Math.abs(lastValue - currentValue) >= 30) {
      //   // continue;
      // }

      // Wenn der Wert grösser ist als der letzte Wert, dann rechnen wir nicht den Durchschnittswert,
      // sondern wir nehmen den Wert selbst
      // if (currentValue > lastValue) {
      //   result = [...result, { label: currentLabel, data: currentValue.toFixed(2) }]
      //   continue;
      // }

      // Wenn der Wert 100% wieder auf 100% gestiegen ist, dann nehmen wir den Wert selbst
      if (currentValue == 100) {
        result = [...result, { label: currentLabel, data: 100.0 }]
        continue
      }

      // Durchschnittswert berechnen
      const avg = values.slice(0, n).reduce((a, b) => a + b) / n
      result = [...result, { label: currentLabel, data: avg.toFixed(2) }]

      if (debug) {
        console.log("")
        // console.log("Echter Wert: " + currentValue + " am " + currentLabel);
        // console.log("AVG Wert: " + avg.toFixed(2) + " am " + currentLabel);
        console.log(`Zeitunterschied: ${timeDiff}h`)
        console.log(`Jetzt: ${currentValue}%, am ${currentDate.toFormat("dd.MM.yyyy HH:mm:ss")}`)
        console.log(`Vorher: ${lastValue}%, am ${lastDate.toFormat("dd.MM.yyyy HH:mm:ss")}`)

        console.log("")
      }
    }

    return result
  }

  // Chart Daten zusammenstellen
  deviceBatteryChartData(data) {
    const device_value_movingAverage_5 = this.movingAverage(5, data.battery_data.labels, data.battery_data.battery_procent_from_device_values)
    const device_value_movingAverage_10 = this.movingAverage(10, data.battery_data.labels, data.battery_data.battery_procent_from_device_values)
    const device_value_movingAverage_20 = this.movingAverage(20, data.battery_data.labels, data.battery_data.battery_procent_from_device_values)
    const device_value_movingAverage_50 = this.movingAverage(50, data.battery_data.labels, data.battery_data.battery_procent_from_device_values)
    const device_value_movingAverage_80 = this.movingAverage(100, data.battery_data.labels, data.battery_data.battery_procent_from_device_values)

    return {
      labels: data.battery_data.labels,
      datasets: [
        {
          label: "Prozentwert aus Gerät Wert",
          data: data.battery_data.battery_procent_from_device_values,
          borderColor: "#CF2906",
          backgroundColor: "#CF290699",
          pointHoverBackgroundColor: "#CF2906",
          tension: 0.2,
          borderWidth: 2,
        },
        {
          label: "Prozentwert aus LoRa Wert",
          data: data.battery_data.battery_procent_from_lora_values,
          borderColor: "#55EB52",
          backgroundColor: "#55EB5299",
          pointHoverBackgroundColor: "#55EB52",
          tension: 0.2,
          borderWidth: 2,
        },
        {
          label: "Geräte Werte (0-255)",
          data: data.battery_data.device_values,
          borderColor: "#36A2EB",
          backgroundColor: "#36A2EB99",
          pointHoverBackgroundColor: "#36A2EB",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
        {
          label: "  LoRa Werte (0-255)",
          data: data.battery_data.lora_values,
          borderColor: "#90CCF4",
          backgroundColor: "#90CCF499",
          pointHoverBackgroundColor: "#90CCF4",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
        {
          label: "Prozentwert aus Gerät Wert (5er Durchschnitt)",
          data: device_value_movingAverage_5.map((item) => item.data),
          borderColor: "#CF2906",
          backgroundColor: "#CF290699",
          pointHoverBackgroundColor: "#CF2906",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
        {
          label: "Prozentwert aus Gerät Wert (10er Durchschnitt)",
          data: device_value_movingAverage_10.map((item) => item.data),
          borderColor: "#CF2906",
          backgroundColor: "#CF290699",
          pointHoverBackgroundColor: "#CF2906",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
        {
          label: "Prozentwert aus Gerät Wert (20er Durchschnitt)",
          data: device_value_movingAverage_20.map((item) => item.data),
          borderColor: "#CF2906",
          backgroundColor: "#CF290699",
          pointHoverBackgroundColor: "#CF2906",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
        {
          label: "Prozentwert aus Gerät Wert (50er Durchschnitt)",
          data: device_value_movingAverage_50.map((item) => item.data),
          borderColor: "#CF2906",
          backgroundColor: "#CF290699",
          pointHoverBackgroundColor: "#CF2906",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
        {
          label: "Prozentwert aus Gerät Wert (80er Durchschnitt)",
          data: device_value_movingAverage_80.map((item) => item.data),
          borderColor: "#CF2906",
          backgroundColor: "#CF290699",
          pointHoverBackgroundColor: "#CF2906",
          tension: 0.2,
          borderWidth: 2,
          hidden: true,
        },
      ],
    }
  }

  // Chart Optionen zusammenstellen
  deviceBatteryChartOptions(data) {
    const { chartMin, chartMax } = this.calcMinMaxChartBorder(data)
    const lines = this.batteryChangeLines(data, chartMin, chartMax)
    return {
      locale: "de-DE",
      pointRadius: 0,
      hitRadius: 20,
      hoverRadius: 10,
      scales: {
        x: {
          display: true,
          type: "time",
          ticks: {
            major: {
              enabled: true,
            },
            font: (context) => {
              const boldedTicks = context.tick && context.tick.major ? "bold" : ""
              return { weight: boldedTicks }
            },
          },
        },
        y: {
          min: chartMin,
          max: chartMax,
        },
      },
      plugins: {
        zoom: {
          zoom: {
            mode: "x",
            onZoomStart: () => this.checkZoomValidity(),
            onZoom: () => this.chartWasZoomed(),
            // Maus zum zoomen
            drag: {
              enabled: true,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
            },
            // Fingergeste zum zoomen
            pinch: {
              enabled: true,
            },
          },
        },
        annotation: {
          annotations: lines,
        },
        autocolors: false,
      },
    }
  }

  checkZoomValidity() {
    const timeStamps = this.chart.scales.x.ticks.map((tick) => tick.value)
    const timeStampStart = timeStamps[0] / 1000
    const timeStampEnd = timeStamps[timeStamps.length - 1] / 1000
    const durationInSeconds = timeStampEnd - timeStampStart
    if (durationInSeconds < 60 * 60) {
      return false
    }
  }

  async chartWasZoomed() {
    $("#btn-reset-chart-zoom").fadeIn()
  }

  hideResetZoomButton() {
    $("#btn-reset-chart-zoom").fadeOut()
  }
}
