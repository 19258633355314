import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-card-handler"
export default class extends Controller {
  connect() {
    this.setLinkVisibility()
  }

  setLinkVisibility() {
    this.maybeDisableConfirmationButton()
    this.maybeDisableAlarmConfirmationLink()
  }

  maybeDisableConfirmationButton() {
    const userIsAuthorized = document.getElementById("user-authorized-for-care-lead")

    if (userIsAuthorized?.value === "true") {
      document.querySelector(".normal-user-only")?.remove()
    } else {
      document.querySelector(".care-lead-only")?.remove()
    }
  }

  maybeDisableAlarmConfirmationLink() {
    const eventId = this.element.dataset.eventId

    const userName = document.querySelector("meta[name='user-name']").content

    const userNameInAlarmConfiratiomTable = document.evaluate(`//table[contains(@id, 'alarm-confirmations-for-event-${eventId}') and contains(., '${userName}')]`, document)
    const userAlreadyConfirmed = userNameInAlarmConfiratiomTable.iterateNext()
    const confirmAlarmLink = document.querySelector(`.confirm-alarm-link-${eventId}`)

    if (userAlreadyConfirmed && confirmAlarmLink) {
      confirmAlarmLink.classList.add("disabled_link")
      confirmAlarmLink.classList.add("text-success")
    }
  }
}
