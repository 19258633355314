import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-form"
export default class extends Controller {
  static targets = ["form"]

  connect() {
    $(".select-select2").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }) // fire a native event
      this.dispatchEvent(event)
    })

    $(".datepicker").on("changeDate", function () {
      const event = new Event("change")
      this.dispatchEvent(event)
    })
  }

  submit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 300)
  }
}
