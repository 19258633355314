import { createChart, updateChart } from "../global/chart_functions"
import { addNewTemplateInstance, listenerForDeletedButtons } from "../global/dynamic_form"

$(document).on("turbo:load", async function () {
  if ($("#sensor-form").length > 0) {
    // Chart Optionen zusammenstellen
    function sampleChartOptions() {
      let { chartMin, chartMax } = calcMinMaxSampleChartBorder()
      return {
        scales: {
          y: {
            min: chartMin,
            max: chartMax,
            ticks: {
              callback: function (value) {
                return value + " °C"
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }
    }

    // Chart Daten zusammenstellen
    function sampleChartData() {
      return {
        datasets: [{}],
      }
    }

    // Sample Chart erstellen
    let chartData = sampleChartData()
    let chartOptions = sampleChartOptions()
    let jsonData = { sensor_alarm_rule_values: "sampleChart" }
    let sampleChart = await createChart("line", "sensor-alarm-rule-sample-chart", { data: chartData, options: chartOptions, jsonData: jsonData })

    listenerForDeletedButtons()
    listenerForSensorAlarmRulesValueChange(sampleChart)

    $("#new-sensor-alarm-rule").on("click", function () {
      let currentTime = new Date().getTime()
      addNewTemplateInstance(currentTime, "sensor-alarm-rules")
      listenerForDeletedButtons()
      listenerForSensorAlarmRulesValueChange(sampleChart)
    })

    // Durch alle Report Zeitabstands-Möglichkeiten loopen und
    // die hervorheben, welche aktiviert sind
    $(".custom-collection-check-box input").each(function (current_id) {
      if ($(this).prop("checked")) {
        $(this).parent().addClass("active")
      }
    })
  }
})

// Beispiels Chart für die SensorAlarm Regeln
// Ober -/ Untergrenze von Chart bestimmen
function calcMinMaxSampleChartBorder() {
  let sensorSettingValues = []
  $(".sensor-alarm-rule-value-input").each(function () {
    const valueInput = parseFloat($(this).val())
    if (!Number.isNaN(valueInput)) {
      sensorSettingValues.push(valueInput)
    }
  })

  const chartMin = Math.min(...sensorSettingValues)
  const chartMax = Math.max(...sensorSettingValues)
  return { chartMin: Math.round(chartMin) - 2, chartMax: Math.round(chartMax) + 2 }
}

function listenerForSensorAlarmRulesValueChange(sampleChart) {
  $(".sensor-alarm-rule-value-input").on("input", function () {
    updateChart(sampleChart, [], [], calcMinMaxSampleChartBorder())
  })
}
