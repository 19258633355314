import { StreamActions } from "@hotwired/turbo"
import { isMobileApp } from "../global/utils"

// Gute Anleitungen für StreamActions:
// https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
// https://www.youtube.com/watch?v=OSiGSUo_Jx8

// Für viele StreamActions gibt es bereits Beispiele in der TurboPower library:
// https://github.com/marcoroth/turbo_power

StreamActions.dispatch_js_event = function () {
  const eventName = this.getAttribute("event_name")
  const event = new Event(eventName)
  window.dispatchEvent(event)
}

StreamActions.notify = function () {
  const type = this.getAttribute("type")
  const message = this.getAttribute("message")
  showFlashMessage(type, message)
}

StreamActions.sort_table = function () {
  let rows, i, x, y, shouldSwitch
  let switching = true
  const table = this.targetElements[0]
  const tdIndex = this.getAttribute("td_index")
  const invertNumber = this.getAttribute("invert_number") == "true"

  while (switching) {
    switching = false
    rows = table.rows

    // Durch alle rows loopen
    for (i = 0; i < rows.length - 1; i++) {
      shouldSwitch = false
      x = rows[i].getElementsByTagName("TD")[tdIndex].dataset.value
      y = rows[i + 1].getElementsByTagName("TD")[tdIndex].dataset.value
      if (invertNumber) {
        x = Number(x) * -1
        y = Number(y) * -1
      }

      if (x > y) {
        shouldSwitch = true
        break
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i])
      switching = true
    }
  }
}

StreamActions.remove_with_fade_effect = async function () {
  const duration = this.getAttribute("duration") || 1000
  this.targetElements.forEach(async (target) => {
    target.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: duration,
      easing: "ease-out",
    })
    await new Promise((resolve) => setTimeout(resolve, duration))
    target.remove()
  })
}

function showFlashMessage(type, message) {
  if (isMobileApp) {
    // Flash message an MobileApp Mobile App senden
    window.bridge.postMessage("showFlashMessage", { message: message, type: type })
  }
}
