import "bootstrap-slider"

$(document).on("turbo:load", function () {
  if ($("#radio-txpower-slider").length > 0) {
    $("#radio-txpower-slider").bootstrapSlider({
      ticks: ["-40", "-20", "-16", "-12", "-8", "-4", "-2", "0", "4"],
      ticks_snap_bounds: 20,
      step: 2,
      lock_to_ticks: true,
      formatter: function (value) {
        return value + " dbm"
      },
    })
  }

  if ($("#rssi-threshold-slider").length > 0) {
    $("#rssi-threshold-slider").bootstrapSlider({
      min: -96,
      max: -65,
      formatter: function (value) {
        return value + " dbm"
      },
    })
  }

  if ($("#device-measurement-interval-slider").length > 0) {
    const intervalsAsString = $("#available-measurement-intervals").text().trim()
    const intervals = JSON.parse(intervalsAsString)
    const minInterval = intervals[0]
    const maxInterval = intervals[intervals.length - 1]
    let ticksPositions = []

    intervals.forEach(function (value) {
      ticksPositions.push(((value - minInterval) / (maxInterval - minInterval)) * 100)
    })

    $("#device-measurement-interval-slider").bootstrapSlider({
      ticks: intervals,
      ticks_labels: intervals,
      ticks_positions: ticksPositions,
      lock_to_ticks: true,
      formatter: function (value) {
        return value + " Minuten"
      },
    })
  }
})
