$(document).on("turbo:load turbo:render turbo:frame-render", function () {
  disablePersonField()
  checkDeviceForm()
})

function checkDeviceForm() {
  if ($("#device-main-form").length > 0) {
    toggleSMSCheckboxOptions($("#device_sends_sms:first"))
    toggleMuteCheckboxOptions($("#device_can_be_muted:first"))
  }

  $("#device_sends_sms").on("change", function () {
    toggleSMSCheckboxOptions($(this))
  })

  $("#device_can_be_muted").on("change", function () {
    toggleMuteCheckboxOptions($(this))
  })

  $("select#device_state").on("change", function () {
    disablePersonField()
  })

  $("#device_runaway_protection").on("change", function () {
    if ($(this).is(":checked")) {
      $("#device-zones").removeClass("d-none")
      $('input[name="device[zone_ids][]"]').prop("checked", true)
    } else {
      $("#device-zones").addClass("d-none")
      $('input[name="device[zone_ids][]"]').prop("checked", false)
    }
  })

  // https://gitlab.code.oxon.ch/oxocare/oxocare/-/issues/131
  if ($("#dont-enable-runaway-protection").length > 0) {
    $("#device_runaway_protection").on("click", function () {
      alert("Die Weglaufschutz-Funktion wurde aktualisiert. Um die Verbesserungen nutzen zu können, welchseln Sie dieses Pflegrufarmband mit einem Neuen aus der Reserve aus.")

      if ($("#device_runaway_protection").is(":checked")) {
        $("#device_runaway_protection").prop("checked", false)
      }
    })
  }

  $("#device-delete-current-mute-time").on("click", function () {
    $("#device_muted_until").val(" ")
    $("#device_muted_until").removeClass("border-focus")
  })

  $("select.input-mode-select").on("change", function (e) {
    // Wenn 'deaktiviert' oder 'Weglaufschutz ausschalten bei...' ausgewählt wurde, dann soll das Feld 'Name' ausgeblendet werden
    if (["00", "03", "04"].includes(e.target.value)) {
      const inputField = e.target.closest(".input-mode-wrapper").querySelector(".toogle-name-field")
      inputField.classList.add("d-none")
      inputField.value = ""
    } else {
      e.target.closest(".input-mode-wrapper").querySelector(".toogle-name-field").classList.remove("d-none")
    }
  })
}

function toggleSMSCheckboxOptions(allowedCheckbox) {
  if (allowedCheckbox.is(":checked")) {
    $(".sms-checkboxes").show()
  } else {
    $(".sms-checkboxes").hide()
  }
}

function toggleMuteCheckboxOptions(allowedCheckbox) {
  if (allowedCheckbox.is(":checked")) {
    $(".mute-checkboxes").removeClass("d-none")
  } else {
    $(".mute-checkboxes").addClass("d-none")
  }
}

function disablePersonField() {
  var selectField = $("select#device_state")
  var inputField = $("input#device_person")
  if (selectField.length > 0 && inputField.length > 0) {
    var deviceInUse = selectField.val() === "in_use"
    if (deviceInUse) {
      inputField.removeAttr("readonly")
      if (inputField.val().length === 0) {
        inputField.val(inputField.attr("data-original") || "")
      }
      inputField.removeAttr("data-original")
    } else {
      if (inputField.val()) {
        inputField.attr("data-original", inputField.val())
        inputField.val("")
      }
      inputField.attr("readonly", "readonly")
    }
  }
}
