import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="global-hotkey"
export default class extends Controller {
  static targets = ["searchInput"]

  focusSearchInput() {
    this.searchInputTarget.focus()
  }
}
