import { addNewTemplateInstance, listenerForDeletedButtons } from "../global/dynamic_form"

$(document).on("turbo:load", function () {
  if ($("#customer-form").length > 0) {
    listenerForDeletedButtons()

    // Select-EventListener muss in einer function sein, damit nach hinzufügen neuer Select2 Instanzen
    // der EventListener noch funktioniert
    listenForCallRuleSelectChange()

    // Loop durch alle EventType Select um festezustellen ob Notruf/Alarm ausgwählt ist
    // Wenn ja, muss im select rechts daneben (call_rule_type)
    // Die Option min_confirmation_amount hinzugefügt werden
    $(".event-type-select").each(function (current_id) {
      if ($(this).val() == "alarm") {
        let select2_to_manipulate = $("#customer_call_rules_attributes_" + current_id + "_call_rule_type")
        addMinConfirmationAmountToSelect(select2_to_manipulate)

        // Zeige den call_rule_type im select an
        // Falls call_rule_type == min_confirmation_amount kann dies erst nachträglich angezeigt werden
        let call_rule_type_value = $("#call_rule_type_value_" + current_id).val()
        select2_to_manipulate.val(call_rule_type_value)

        showAndHideInputFields(current_id, call_rule_type_value)
      } else {
        showAndHideInputFields(current_id)
      }
    })

    $("#reset-temporary-forward").on("click", function () {
      $("#customer_temporary_call_forward_to_customer_id").val(null).trigger("change")
    })

    $("#new-call-rule").on("click", function () {
      let current_time = new Date().getTime()
      addNewTemplateInstance(current_time, "call-rules")

      showAndHideInputFields(current_time)
      listenForCallRuleSelectChange()
      listenerForDeletedButtons()
    })

    $("#new-default-device-setting").on("click", function () {
      let current_time = new Date().getTime()
      addNewTemplateInstance(current_time, "default-device-settings")
      listenerForDeletedButtons()
    })

    $("#new-api-key").on("click", function () {
      let current_time = new Date().getTime()
      addNewTemplateInstance(current_time, "api-keys")
      $(`#customer_api_keys_attributes_${current_time}_token_plain`).val(genRanHex(32))
      listenerForDeletedButtons()
    })
  }
})

// 'Von'-'Bis' ZeitFelder ein-/ausblenden
// min-confirmation-amount-inputs- ein-/ausblenden
function showAndHideInputFields(id, call_rule_type_value = "") {
  if (call_rule_type_value == "min_confirmation_amount") {
    $(".min-confirmation-amount-inputs-" + id).show()
    $(".time-from-until-inputs-" + id).hide()

    $(".time-from-until-inputs-" + id + " input").each(function (current_id) {
      clearInputField($(this))
    })
  } else {
    $(".min-confirmation-amount-inputs-" + id).hide()
    $(".time-from-until-inputs-" + id).show()

    $(".min-confirmation-amount-inputs-" + id + " input").each(function (current_id) {
      clearInputField($(this))
    })
  }
}

function clearInputField(input) {
  input.val("")
}

function addMinConfirmationAmountToSelect(select) {
  let new_data = {
    id: "min_confirmation_amount",
    text: "Mindestanzahl Quittierungen",
  }

  if (select.find("option[value=" + new_data.id + "]").length == 0) {
    let new_option = new Option(new_data.text, new_data.id, false, false)
    select.append(new_option)
  }
}

function removeMinConfirmationAmountFromSelect(select) {
  select.find('option[value="min_confirmation_amount"]').remove()
}

// CallRule EventListener für Dropdowns
function listenForCallRuleSelectChange() {
  $(".event-type-select").on("change", function () {
    let current_id = $(this)[0].id.match(/\d+/)[0]
    let call_rule_type_select2 = $("#customer_call_rules_attributes_" + current_id + "_call_rule_type")

    if ($(this).val() == "alarm") {
      addMinConfirmationAmountToSelect(call_rule_type_select2)
    } else {
      removeMinConfirmationAmountFromSelect(call_rule_type_select2)
      showAndHideInputFields(current_id)
    }
  })

  $(".call-rule-type-select").on("change", function () {
    let current_id = $(this)[0].id.match(/\d+/)[0]
    showAndHideInputFields(current_id, $(this).val())
  })
}

function genRanHex(size) {
  return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("")
}
