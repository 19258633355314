// const Chart = require('chart.js');
import Chart from "chart.js/auto"

import "chartjs-adapter-luxon"
import annotationPlugin from "chartjs-plugin-annotation"
import zoomPlugin from "chartjs-plugin-zoom"

Chart.register(zoomPlugin)
Chart.register(annotationPlugin)

async function createChart(chartType, chartId, chartData) {
  const ctx = document.getElementById(chartId).getContext("2d")

  // Standardmässig gradient mit den normalen BackgroundColors befüllen
  if (chartData.data.datasets[0].gradient == true) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, "rgba(7, 130, 196,0.8)")
    gradient.addColorStop(1, "rgba(0,154,255,0.3)")
    chartData.data.datasets[0].backgroundColor = gradient
  }

  // Erlaubte Ober -/ Untergrenzen zeichnen
  const arbitraryLines = {
    id: "arbitraryLines",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, heigth },
        scales: { x, y },
      } = chart
      ctx.save()

      let alarmRuleValues = []

      // Loope durch alle Inputs um die Grenzen zu finden
      $(".sensor-alarm-rule-value-input").each(function () {
        const valueInput = parseFloat($(this).val())
        if (!Number.isNaN(valueInput)) {
          alarmRuleValues.push(valueInput)
        }
      })
      alarmRuleValues = alarmRuleValues.sort((a, b) => a - b)

      // Zeichnen der Ober -/ Untergrenze
      alarmRuleValues.forEach(function (value, index) {
        if (index === 0 || index + 1 === alarmRuleValues.length) {
          ctx.setLineDash([0]) // Erste und letzte Linie durchziehen
        } else {
          ctx.setLineDash([10]) // Alle dazwischen gestrichelt
        }
        ;(ctx.strokeStyle = "red"), ctx.strokeRect(left, y.getPixelForValue(value), width, 0)
      })

      ctx.restore()
    },
  }

  // Chart erstellen
  let chartConfig = { type: chartType, data: chartData.data, options: chartData.options }
  if (chartData.jsonData && chartData.jsonData.sensor_alarm_rule_values === "sampleChart") {
    chartConfig.plugins = [arbitraryLines]
  }

  return new Chart(ctx, chartConfig)
}

// Labels und Datenpunkte für ein einzelner Chart updaten
function updateChart(chart, newLabels, newData, newBorderInfo) {
  chart.data.labels = []
  chart.data.datasets.data = []

  chart.data.labels = newLabels
  chart.data.datasets.forEach((dataset) => {
    dataset.data = newData
  })

  if (newBorderInfo) {
    chart.options.scales.y.min = newBorderInfo.chartMin
    chart.options.scales.y.max = newBorderInfo.chartMax
  }
  chart.update()
}

// Chart Optionen definieren
function generalEventLineChartOptions(data) {
  return {
    locale: "de-DE",
    pointRadius: 1,
    hitRadius: 20,
    hoverRadius: 10,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + " Ereignisse"
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            let label = context.formattedValue || ""
            return label + " Ereignisse"
          },
        },
      },
    },
  }
}
function generalEventDoughnutChartOptions(data) {
  return {
    locale: "de-DE",
    maintainAspectRatio: false,
  }
}

export { createChart, updateChart, generalEventLineChartOptions, generalEventDoughnutChartOptions }
