import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visibility-toggle"
export default class extends Controller {
  static targets = ["elementToToggle", "select"]
  static values = {
    dependsOnSelect: { type: Boolean, default: false },
    defaultVisibility: { type: String, default: "show" },
    conditional: String,
  }

  connect() {
    if (this.dependsOnSelectValue) {
      $(this.selectTarget).on("select2:select", (event) => {
        event.target.value === this.conditionalValue ? this.show() : this.hide()
      })
    }

    // Default
    if (this.defaultVisibilityValue === "hide") {
      this.hide()
    }
    if (this.defaultVisibilityValue === "show") {
      this.show()
    }
  }

  toggle() {
    this.elementToToggleTargets.forEach((element) => element.classList.toggle("d-none"))
  }

  show() {
    this.elementToToggleTargets.forEach((element) => element.classList.remove("d-none"))
  }

  hide() {
    this.elementToToggleTargets.forEach((element) => element.classList.add("d-none"))
  }
}
