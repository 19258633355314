import { Controller } from "@hotwired/stimulus"
import { AppearanceObserver } from "../global/appearance_observer"
import { setDatepickerFields } from "../global/bootstrap_datepicker"
import { createChart, updateChart } from "../global/chart_functions"
import { pageIsTurboPreview } from "../global/utils"
import { FetchRequest } from "@rails/request.js"
import { DateTime } from "luxon"

// Connects to data-controller="sensor-chart"
export default class extends Controller {
  static values = {
    timestampStart: { type: Number, default: DateTime.local().minus({ hours: 24 }).toSeconds() },
    timestampEnd: { type: Number, default: DateTime.now().toSeconds() },
    period: { type: Object, default: { hours: 24 } },
    showDetails: { type: Boolean, default: false },
    sensorId: Number,
  }

  initialize() {
    this.appearanceObserver = new AppearanceObserver(this, this.element)
  }

  connect() {
    if (!pageIsTurboPreview()) {
      this.chart?.destroy()

      this.appearanceObserver.start()
    }
  }

  disconnect() {
    this.chart?.destroy()
  }

  // Chart wird erst erstellt, wenn das Element im Viewport erscheint
  // So können wir uns unnötige Server Requests sparen
  elementAppearedInViewport() {
    this.appearanceObserver.stop()
    this.plotChart()
  }

  plotChart() {
    this.initializeChart().then((chart) => {
      this.chart = chart
    })

    setDatepickerFields(this.timestampStartValue, this.timestampEndValue, true)
    this.startDatepickerListener()
  }

  changeDisplayedData(event) {
    const duration = event.target.dataset.duration
    switch (duration) {
      case "month":
        this.periodValue = { month: 1 }
        this.timestampStartValue = DateTime.local().minus({ month: 1 }).startOf("day").toSeconds()
        break
      case "week":
        this.periodValue = { week: 1 }
        this.timestampStartValue = DateTime.local().minus({ week: 1 }).startOf("day").toSeconds()
        break
      case "24-hours":
        this.periodValue = { hours: 24 }
        this.timestampStartValue = DateTime.local().minus({ hours: 24 }).toSeconds()
        break
      case "2-hours":
        this.periodValue = { hours: 2 }
        this.timestampStartValue = DateTime.local().minus({ hours: 2 }).toSeconds()
        break
      default:
        this.periodValue = { hours: 24 }
        this.periodValue = { hours: 24 }
    }

    // Button als aktiv markieren - alle anderen Buttons deaktivieren
    event.target.classList.add("active")
    event.target.parentElement.querySelectorAll("button").forEach((button) => {
      if (button != event.target) {
        button.classList.remove("active")
      }
    })

    this.timestampEndValue = DateTime.now().toSeconds()
    this.updateSensorData()
  }

  downloadPDF() {
    const request = new FetchRequest("GET", `${this.sensorIdValue}?dowload_pdf=true&timestamp_start=${this.timestampStartValue}&timestamp_end=${this.timestampEndValue}`, { responseKind: "turbo-stream" })
    request.perform()
  }

  oneIterationBack() {
    this.timestampStartValue = DateTime.fromSeconds(this.timestampStartValue).minus(this.periodValue).startOf("day").toSeconds()
    this.timestampEndValue = DateTime.fromSeconds(this.timestampEndValue).minus(this.periodValue).toSeconds()
    this.updateSensorData()
  }

  oneIterationForth() {
    let newTimestampStart = DateTime.fromSeconds(this.timestampStartValue).plus(this.periodValue).startOf("day").toSeconds()
    let newTimestampEnd = DateTime.fromSeconds(this.timestampEndValue).plus(this.periodValue).toSeconds()

    // Man darf nicht in die Zukunft blättern können
    if (newTimestampStart > DateTime.now().toSeconds()) {
      newTimestampStart = DateTime.now().toSeconds()
    }
    if (newTimestampEnd > DateTime.now().toSeconds()) {
      newTimestampEnd = DateTime.now().toSeconds()
    }
    if (newTimestampStart == newTimestampEnd) {
      return
    }
    this.timestampStartValue = newTimestampStart
    this.timestampEndValue = newTimestampEnd
    this.updateSensorData()
  }

  resetChartZoom() {
    this.chart.resetZoom()
    this.hideResetZoomButton()
    this.updateSensorData()
  }

  timeStartChanged(event) {
    const hoursMins = event.target.value.split(":")
    this.timestampStartValue = DateTime.fromSeconds(this.timestampStartValue).startOf("day").plus({ hours: hoursMins[0] }).plus({ minutes: hoursMins[1] }).toSeconds()
    this.updateSensorData()
  }

  timeEndChanged(event) {
    const hoursMins = event.target.value.split(":")
    this.timestampEndValue = DateTime.fromSeconds(this.timestampEndValue).startOf("day").plus({ hours: hoursMins[0] }).plus({ minutes: hoursMins[1] }).toSeconds()
    this.updateSensorData()
  }

  async initializeChart() {
    // Sämtliche Sensor Daten holen
    const jsonData = await this.getSensorData(this.timestampStartValue, this.timestampEndValue)

    // Min -/ Max Werte anzeigen
    this.displayMinMaxAndAvg(jsonData.sensor_data.min_reached, jsonData.sensor_data.max_reached, jsonData.sensor_data.avg_value)

    // Chart Optionen generieren und Chart anzeigen
    const chartData = this.sensorChartData(jsonData)
    const chartOptions = this.sensorChartOptions(jsonData)

    if (this.showDetailsValue) {
      // Sensor Daten Tabelle rendern, wenn Sensor#show angezeigt wird
      this.displayAdditionalSensorInfo("sensor_events")
    }

    return createChart("line", this.sensorIdValue, { data: chartData, options: chartOptions, jsonData: jsonData })
  }

  async getSensorData(start = this.timestampStartValue, end = this.timestampEndValue) {
    const response = await fetch(`/sensors/${this.sensorIdValue}.json?timestamp_start=${start}&timestamp_end=${end}`)
    const data = await response.json()
    return data
  }

  additionalSensorDataAfterSuccess(whichData, html) {
    if (whichData == "sensor_events") {
      $("#sensor-events-table").html(html)
    }
  }

  // Zusätzliche Infos wie, SensorDaten Punkte oder Events holen und anzeigen
  displayAdditionalSensorInfo(whichData) {
    const sensorUrl = `${window.location.pathname}?which_data=${whichData}&timestamp_start=${this.timestampStartValue}'&timestamp_end=${this.timestampEndValue}`
    $.ajax({
      url: sensorUrl,
      cache: false,
      success: (html) => {
        this.additionalSensorDataAfterSuccess(whichData, html)
      },
    })
  }

  // Alle angezeigten Daten updaten
  async updateSensorData() {
    setDatepickerFields(this.timestampStartValue, this.timestampEndValue, true)

    // Neue Chart Daten holen
    const newChartData = await this.getSensorData()

    // Werte im Chart updaten
    updateChart(this.chart, newChartData["sensor_data"].labels, newChartData["sensor_data"].values, this.calcMinMaxChartBorder(newChartData))

    // Min Max Werte darstellen
    this.displayMinMaxAndAvg(newChartData.sensor_data.min_reached, newChartData.sensor_data.max_reached, newChartData.sensor_data.avg_value)

    // Plugins für Chart updaten
    // AlarmRegeln
    const alarmLines = this.sensorAlarmRuleLines(newChartData)
    this.chart.options.plugins.annotation = { annotations: alarmLines }

    // Zoom
    this.chart.resetZoom()
    this.hideResetZoomButton()

    // Zusätzliche Infos wie, SensorDaten Punkte oder Events holen und anzeigen
    this.displayAdditionalSensorInfo("sensor_events")
  }

  // Herausfinden ob AlarmRule ersichtlich ist im aktuellen Zeitfenster
  // gelöschte AlarmRules sind je nach Zeitfenster nicht ersichtlich
  isCurrentlyDisplayed(rule) {
    const start = this.timestampStartValue
    const end = this.timestampEndValue

    const ruleIsCompletelyShown = rule.active_from_timestamp > start && rule.active_till_timestamp < end
    const ruleLineIsLargerThenCurrentlyDisplayed = start > rule.active_from_timestamp && start < rule.active_till_timestamp
    const endOfTheRuleLineIsntCurrentlyDisplayed = end > rule.active_from_timestamp && end < rule.active_till_timestamp

    if (ruleIsCompletelyShown || ruleLineIsLargerThenCurrentlyDisplayed || endOfTheRuleLineIsntCurrentlyDisplayed) {
      return true
    }
  }

  // Wert der grössten oder kleinsten Sensor Alarm Rule holen
  allowedValues(data, showBiggestMaxAndLowestMin, specificTime = null) {
    let alarmRules, allowedMinValue, allowedMaxValue

    if (specificTime) {
      alarmRules = this.alarmRulesForSpecificTime(specificTime, data.sensor_alarm_rules_details)
    } else {
      alarmRules = data.sensor_alarm_rules_details.filter((rule) => this.isCurrentlyDisplayed(rule))
    }

    let allowedMinValues = alarmRules.filter((rule) => rule.setting_type == "min_value").map((rule) => rule.value)
    let allowedMaxValues = alarmRules.filter((rule) => rule.setting_type == "max_value").map((rule) => rule.value)

    if (showBiggestMaxAndLowestMin) {
      allowedMinValue = Math.min(...allowedMinValues)
      allowedMaxValue = Math.max(...allowedMaxValues)
    } else {
      allowedMinValue = Math.max(...allowedMinValues)
      allowedMaxValue = Math.min(...allowedMaxValues)
    }

    return { allowedMaxValue: allowedMaxValue, allowedMinValue: allowedMinValue }
  }

  allAlarmRuleValues(data) {
    const alarmRules = data.sensor_alarm_rules_details.filter((rule) => this.isCurrentlyDisplayed(rule))
    return alarmRules.map((rule) => rule.value)
  }

  // AlarmRegeln welche zu einem bestimmten Zeitpunkt exisitert haben
  // (Wird benötigt um bei älteren Messdaten die damals aktiven AlarmRegeln zu bekommen)
  alarmRulesForSpecificTime(chartJsTimeStampInMilliseconds, alarmRules) {
    const specificTime = chartJsTimeStampInMilliseconds / 1000

    const rules = alarmRules.filter(function (alarmRule) {
      if (specificTime > alarmRule.active_from_timestamp && specificTime < alarmRule.active_till_timestamp) {
        return true
      }
    })
    return rules
  }

  // Ober -/ Untergrenze von Chart bestimmen
  calcMinMaxChartBorder(data, space = 2) {
    let allValues = []
    allValues.push(this.allAlarmRuleValues(data)) // Alle AlarmRegel Werte
    allValues.push(data.sensor_data.values) // Alle Messwerte
    allValues = allValues.flat().filter((element) => {
      // Alle Werte in einem Array zusammenfassen und null Werte entfernen
      return element !== null && element !== undefined
    })

    const chartMin = Math.min(...allValues)
    const chartMax = Math.max(...allValues)

    return { chartMin: Math.round(chartMin) - space, chartMax: Math.round(chartMax) + space }
  }

  // Chart Daten zusammenstellen
  sensorChartData(data) {
    // p0 ist der jetztige Datenpunkt. P1 der nächste vom Segment
    // p0.parsed.y entspricht dem Messpunkt Wert
    // p0.parsed.x entspricht dem Messpunkt timestamp
    const segmentIsInAlarmZone = (ctx, value) => {
      let { allowedMaxValue, allowedMinValue } = this.allowedValues(data, false, ctx.p0.parsed.x)
      // Wenn null ist, soll der Strich nicht zu sehen sein
      if (ctx.p0.parsed.y == null) {
        return "rgba(255, 255, 255, 1)"
      } // Wenn der jetzige oder der nächste Punkt über -/ unter der max -/ min Grenze liegt soll der Strich rot sein
      else if (ctx.p0.parsed.y < allowedMinValue || ctx.p0.parsed.y > allowedMaxValue || ctx.p1.parsed.y < allowedMinValue || ctx.p1.parsed.y > allowedMaxValue) {
        return value
      }
    }

    const pointIsInAlarmZone = (ctx, value) => {
      let { allowedMaxValue, allowedMinValue } = this.allowedValues(data, false, ctx.parsed.x)
      if (ctx.parsed.y < allowedMinValue || ctx.parsed.y > allowedMaxValue) {
        return value
      }
    }

    return {
      labels: data.sensor_data.labels,
      datasets: [
        {
          data: data.sensor_data.values,
          tension: 0.4,
          borderWidth: 2,
          pointHoverBackgroundColor: (ctx) => pointIsInAlarmZone(ctx, "rgba(225, 56, 0, 1)") || "rgba(54, 162, 235, 1)",
          segment: {
            borderColor: (ctx) => segmentIsInAlarmZone(ctx, "rgba(225, 56, 0, 1)") || "rgba(54, 162, 235, 1)",
          },
        },
      ],
    }
  }

  // Alarm Regeln zeichen
  sensorAlarmRuleLines(data) {
    let lines = {}
    let activeFrom,
      activeTill = ""

    const alarmRules = data.sensor_alarm_rules_details.filter((rule) => this.isCurrentlyDisplayed(rule))
    alarmRules.forEach((alarmRule, index) => {
      // Von wenn bis wenn war oder ist die Regel aktiv
      activeFrom = DateTime.fromSeconds(alarmRule.active_from_timestamp).toISO()
      activeTill = DateTime.fromSeconds(alarmRule.active_till_timestamp).toISO()

      // Wenn die AlarmRegel schon länger existiert als das aktuell angezeigte Zeitfenster im Chart anzeigen könnte
      if (alarmRule.active_from_timestamp < this.timestampStartValue) {
        activeFrom = DateTime.fromSeconds(this.timestampStartValue).toISO()
      }

      // Wenn in die Vergangenheit geschaut wird, AlarmRule Linie kürzen
      if (alarmRule.active_till_timestamp > this.timestampEndValue) {
        activeTill = DateTime.fromSeconds(this.timestampEndValue).toISO()
      }

      const lineConfiguration = {
        type: "line",
        borderColor: alarmRule.is_active ? "rgba(225, 56, 0, 1)" : "rgba(40, 69, 85, 0.4)",
        borderWidth: 1,
        borderDash: [0],
        label: {
          enabled: this.showDetailsValue,
          backgroundColor: alarmRule.is_active ? "rgba(225, 56, 0, 0.8)" : "rgba(40, 69, 85, 0.6)",
          color: "white",
          content: alarmRule.label,
          font: {
            size: 10,
          },
        },
        yMax: alarmRule.value,
        yMin: alarmRule.value,
        yScaleID: "y",
        xMax: activeTill,
        xMin: activeFrom,
        xScaleID: "x",
      }
      lines[`line${index}`] = lineConfiguration
    })

    return lines
  }

  // Chart Optionen zusammenstellen
  sensorChartOptions(data) {
    let { chartMin, chartMax } = this.calcMinMaxChartBorder(data)
    let alarmLines = this.sensorAlarmRuleLines(data)
    return {
      locale: "de-DE",
      pointRadius: 0,
      hitRadius: 20,
      hoverRadius: 10,
      scales: {
        x: {
          type: "time",
          adapters: {
            date: {
              locale: "de-DE",
              setZone: true,
              zone: "Europe/Zurich",
            },
          },
          ticks: {
            major: {
              enabled: true,
            },
            font: (context) => {
              const boldedTicks = context.tick && context.tick.major ? "bold" : ""
              return { weight: boldedTicks }
            },
          },
        },
        y: {
          min: chartMin,
          max: chartMax,
          ticks: {
            callback: function (value) {
              return value + " °C"
            },
          },
        },
      },
      plugins: {
        zoom: {
          zoom: {
            mode: "x",
            onZoomStart: () => this.checkZoomValidity(),
            onZoom: () => this.chartWasZoomed(),
            // Maus zum zoomen
            drag: {
              enabled: true,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
            },
            // Fingergeste zum zoomen
            pinch: {
              enabled: this.showDetailsValue,
            },
          },
        },
        autocolors: false,
        annotation: {
          annotations: alarmLines,
        },
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          yAlign: "bottom",
          callbacks: {
            label: function (context) {
              let label = context.formattedValue || ""
              return label + " °C"
            },
          },
        },
      },
    }
  }

  checkZoomValidity() {
    const timeStamps = this.chart.scales.x.ticks.map((tick) => tick.value)
    const timeStampStart = timeStamps[0] / 1000
    const timeStampEnd = timeStamps[timeStamps.length - 1] / 1000
    const durationInSeconds = timeStampEnd - timeStampStart
    if (durationInSeconds < 60 * 60) {
      return false
    }
  }

  async chartWasZoomed() {
    const timeStamps = this.chart.scales.x.ticks.map((tick) => tick.value)
    const timeStampStart = timeStamps[0] / 1000
    const timeStampEnd = timeStamps[timeStamps.length - 1] / 1000
    setDatepickerFields(timeStampStart, timeStampEnd, true)
    $("#btn-reset-chart-zoom").fadeIn()

    // Neue Chart Daten holen
    const newChartData = await this.getSensorData(timeStampStart, timeStampEnd)

    // Min Max Werte darstellen
    this.displayMinMaxAndAvg(newChartData.sensor_data.min_reached, newChartData.sensor_data.max_reached, newChartData.sensor_data.avg_value)
  }

  hideResetZoomButton() {
    $("#btn-reset-chart-zoom").fadeOut()
  }

  displayMinMaxAndAvg(min, max, avg) {
    if (min != null) {
      // if min is a number, round it to 2 decimal places
      if (typeof min === "number") {
        min = min.toFixed(2)
      }
      $(`#${this.sensorIdValue}-reached-min-value`).html(`${min} °C`)
    }

    if (max != null) {
      if (typeof max === "number") {
        max = max.toFixed(2)
      }
      $(`#${this.sensorIdValue}-reached-max-value`).html(`${max} °C`)
    }

    if (avg != null) {
      if (typeof avg === "number") {
        avg = avg.toFixed(2)
      }
      $(`#${this.sensorIdValue}-average-value`).html(`${avg} °C`)
    }
  }

  startDatepickerListener() {
    // Datepicker 'changeDate' event triggered auch bei input-Field änderungen von JS
    // Nur wenn Datepicker auswählt wird, soll 'changeDate' event triggern
    let datepickerWasChanged = false
    $("#datepicker-start, #datepicker-end").on("focus", function (e) {
      datepickerWasChanged = true
    })
    $("#datepicker-start, #datepicker-end").on("changeDate", (e) => {
      if (datepickerWasChanged) {
        datepickerWasChanged = false
        $("#sensor-data-last-two-hour").removeClass("active").siblings().removeClass("active")
        const startDate = DateTime.fromFormat($("#datepicker-start").val(), "dd.MM.yyyy").startOf("day").toSeconds()
        const endDate = DateTime.fromFormat($("#datepicker-end").val(), "dd.MM.yyyy").endOf("day").toSeconds()
        if (startDate > endDate) {
          // Startdatum darf nicht grösser sein als Enddatum
          // Reset Datepicker und exist
          setDatepickerFields(this.timestampStartValue, this.timestampEndValue, true)
          return
        }
        this.timestampStartValue = startDate
        this.timestampEndValue = endDate
        this.updateSensorData()
      }
    })
  }
}
