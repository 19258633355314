import { DateTime } from "luxon"

function setDatepickerFields(startDate, endDate, setTimeFields = false) {
  $("#datepicker-start, #datepicker-end").datepicker({
    format: "dd.mm.yyyy",
    language: "de",
  })

  $("#datepicker-start").datepicker("setDate", DateTime.fromSeconds(startDate).toLocaleString(DateTime.DATE_SHORT, { locale: "de" }))
  $("#datepicker-end").datepicker("setDate", DateTime.fromSeconds(endDate).toLocaleString(DateTime.DATE_SHORT, { locale: "de" }))

  if (setTimeFields) {
    $("#time-start").val(DateTime.fromSeconds(startDate).toLocaleString(DateTime.TIME_24_SIMPLE))
    $("#time-end").val(DateTime.fromSeconds(endDate).toLocaleString(DateTime.TIME_24_SIMPLE))
  }
}

export { setDatepickerFields }
