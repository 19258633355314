import { Controller } from "@hotwired/stimulus"
import QrScanner from "qr-scanner"

// Connects to data-controller="qr-scanner"
export default class extends Controller {
  static targets = ["video", "noCameraContainer", "videoContainer"]
  static values = {
    preferredCamera: { type: String, default: "environment" }, // prefer the back camera
    highlightScanRegion: { type: Boolean, default: true },
  }

  connect() {
    // Native Browser Barcode Detection nicht verwenden, da die Performance stand 2024 nicht wirklich gut ist
    delete window.BarcodeDetector

    this.qrScanner = new QrScanner(this.videoTarget, (result) => this.dispatch("result", { detail: { content: result } }), this.options)

    // Ohne InversionMode `both` werden nur dunkle QR-Codes auf hellem Hintergrund erkannt.
    // Mit  InversionMode `both` werden zusätzlich auch helle QR-Codes auf dunklem Hintergrund erkannt.
    this.qrScanner.setInversionMode("both")
  }

  disconnect() {
    this.qrScanner.destroy()
    if (this.hasVideoContainerTarget) {
      this.videoContainerTarget.classList.add("d-none")
    }
  }

  async start() {
    await this.qrScanner.start()
    this.showVideoFeed()
  }

  stop() {
    this.qrScanner.stop()
  }

  showNoCameraContainer() {
    if (this.hasNoCameraContainerTarget) {
      this.noCameraContainerTarget.classList.remove("d-none")
    }

    if (this.hasVideoContainerTarget) {
      this.videoContainerTarget.classList.add("d-none")
    }
  }

  showVideoFeed() {
    if (this.hasNoCameraContainerTarget) {
      this.noCameraContainerTarget.classList.add("d-none")
    }

    if (this.hasVideoContainerTarget) {
      this.videoContainerTarget.classList.remove("d-none")
    }
  }

  get options() {
    return {
      preferredCamera: this.preferredCameraValue,
      highlightScanRegion: this.highlightScanRegionValue,
    }
  }
}
