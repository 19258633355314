import { Controller } from "@hotwired/stimulus"
import swal from "sweetalert"

// Connects to data-controller="prevent-click"
export default class extends Controller {
  static targets = ["checkbox"]
  static values = {
    message: String,
    buttonText: { type: String, default: "OK" },
  }

  connect() {
    console.log("this.messageValue", this.messageValue)

    if (this.hasCheckboxTarget) {
      this.boundClickEvent = this.preventToggle.bind(this)
      this.checkboxTarget.addEventListener("click", this.boundClickEvent)
    }
  }

  disconnect() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.removeEventListener("click", this.boundClickEvent)
    }
  }

  preventToggle(event) {
    if (this.checkboxTarget.checked) {
      event.preventDefault()
      console.log("this.messageValue", this.messageValue)

      swal(this.messageValue, {
        button: this.buttonTextValue,
        icon: "warning",
      })
    }
  }
}
