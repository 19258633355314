import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="partials--dashboard"
export default class extends Controller {
  static targets = ["eventCard", "noEventsHint"]

  eventCardTargetConnected() {
    this.toggleEventHint()
  }

  eventCardTargetDisconnected() {
    this.toggleEventHint()
  }

  toggleEventHint() {
    if (this.hasEventCardTarget) {
      this.noEventsHintTarget.classList.add("d-none")
    } else {
      this.noEventsHintTarget.classList.remove("d-none")
    }
  }
}
