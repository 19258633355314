import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="datepicker"
export default class extends Controller {
  connect() {
    console.log("Datepicker connected")

    $(this.element)
      .datepicker({
        endDate: "0d",
        autoclose: true,
        format: "dd.mm.yyyy",
        todayBtn: "linked",
        todayHighlight: true,
      })
      .on("changeDate", () => {
        // Event dispatchen, damit bei einer Änderung via Stimulus action darauf reagiert werden kann
        this.element.dispatchEvent(new Event("change"))
      })
  }
}
