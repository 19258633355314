import { DateTime } from "luxon"

// Neues Form Template Anzeigen
function addNewTemplateInstance(current_time, name) {
  const newFormHTML = $(`#${name}-template`).html().replace(/idx/g, current_time)
  const new_select2 = ".select-select2_" + current_time
  $(newFormHTML).appendTo(`#${name}-insert`)

  // Neue Select2 Instanzen intializieren
  $(new_select2.toString()).select2({
    theme: "bootstrap4",
    language: "de",
    minimumResultsForSearch: 10,
  })
}

// Entry ausblenden wenn Lösch-Button gedrückt wird.
function deleteEntry(index, formName, entryName) {
  let entryBox = $(`#${entryName}-${index}`)
  entryBox.delay(300).fadeOut("slow")

  // Param "_destroy" = true markieren, dass sie im Controller wirklich gelöscht werden
  let persistedRecordDestroyField = $(`#${formName}_${entryName.replace(/-/g, "_")}_attributes_${index}__destroy`)
  if (persistedRecordDestroyField.length > 0) {
    // "deleted_at" abfüllen wenn vorhanden
    let deletedAtField = $(`#${formName}_${entryName.replace(/-/g, "_")}_attributes_${index}_deleted_at`)
    if (deletedAtField.length > 0) {
      deletedAtField.val(DateTime.now().toISO())
    } else {
      persistedRecordDestroyField.val("true")
    }
    entryBox.fadeOut("slow")
  } else {
    entryBox.fadeOut("slow", function () {
      entryBox.remove()
    })
  }

  if (entryName == "sensor-alarm-rules") {
    // Sensor Alarm Regel °C Inhalt auf '' setzen, damit im SampleChart die Regel verschwindet
    $(`#${entryName}-${index} .sensor-alarm-rule-value-input`).val("").trigger("input")
  }
}

// EventListener für Lösch-Buttons
// Die Funktion muss immer wieder aufgerufen werden,
// Da immer neue Lösch-Buttons entstehen
function listenerForDeletedButtons() {
  // Customer Form
  $(".delete-call-rules").on("click", function () {
    const index = $(this).data("call-rule-index")
    deleteEntry(index, "customer", "call-rules")
  })

  $(".delete-default-device-settings").on("click", function () {
    const index = $(this).data("default-device-setting-index")
    deleteEntry(index, "customer", "default-device-settings")
  })

  $(".delete-api-key").on("click", function () {
    const index = $(this).data("api-key-index")
    deleteEntry(index, "customer", "api-keys")
  })

  // Device-Sensor Form
  $(".delete-sensor-alarm-rules").on("click", function () {
    const index = $(this).data("sensor-alarm-rule-index")
    deleteEntry(index, "device", "sensor-alarm-rules")
  })

  // DeviceType Form
  $(".delete-device-type-feature").on("click", function () {
    const index = $(this).data("device-type-feature-index")
    deleteEntry(index, "device_type", "device-type-features")
  })

  $(".delete-device-type-usage").on("click", function () {
    const index = $(this).data("device-type-usage-index")
    deleteEntry(index, "device_type", "device-type-usages")
  })
}

export { addNewTemplateInstance, listenerForDeletedButtons }
