// Bestimmte Elemente wollen wir standardmässig vor jedem Turbo Cache entfernen -/ schliessen
document.addEventListener("turbo:before-cache", function () {
  $("select.select2-hidden-accessible").select2("destroy")
  $(".bootstrap-slider").bootstrapSlider("destroy")

  // Tooltips entfernen
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => bootstrap.Tooltip.getInstance(element)?.dispose())

  // Popovers entfernen
  document.querySelectorAll('[data-bs-toggle="popover"]').forEach((element) => bootstrap.Popover.getInstance(element)?.dispose())

  // Alle Modals entfernen
  document.querySelector("#remote-modal .modal")?.remove()

  // Sämtliche animate.css klassen entfernen
  document.querySelectorAll(".animate__animated").forEach((element) => {
    const classesToRemove = [...element.classList].filter((className) => className.startsWith("animate__"))
    element.classList.remove(...classesToRemove)
  })
})
