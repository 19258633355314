import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { mobileAppUniqueDeviceId } from "../../global/utils"
import { removeFCMTokenCache } from "../../turbo/native_actions/push_notifications"

// Dies ist unser Bridge Controller, mit dem wir Nachrichten an die Turbo Native App senden können.
// Wichtig: Dies hat nichts mit der Strada Bridge zu tun.

// Connects to data-controller="native--bridge"
export default class extends Controller {
  static values = {
    action: String,
    payload: Object,
    autoSend: { type: Boolean, default: false },
    delayedSend: { type: Boolean, default: false },
    delaySeconds: { type: Number, default: 0 },
  }

  connect() {
    if (this.autoSendValue) {
      this.postJSToNative()
    } else if (this.delayedSendValue) {
      setTimeout(() => {
        this.postJSToNative()
      }, this.delaySecondsValue * 1000)
    }
  }

  // Generische Möglichkeit eine Nachricht an die Turbo Native App zu senden
  postJSToNative() {
    window.bridge.postJSToNative(this.actionValue, this.payloadValue)
  }

  // Wenn eine Turbo Native App abgemeldet werde soll, machen wir manuell einen request auf /sign_out_mobile_app um alle MobileAppBezogene Daten zu löschen
  // anschliessend informieren wir die App per JS Bridge um der App die Möglichkeit zu geben, gespeicherte Daten zu löschen.
  // Zu guter letzt leiten wir den Benutzer auf die Login Seite weiter.
  async signOut({ params: { withRedirect } }) {
    const response = await post("/mobile_app/sign_out", { body: { unique_device_id: mobileAppUniqueDeviceId } })
    if (response.ok) {
      removeFCMTokenCache()
      window.bridge.postJSToNative("didSignOut")
      if (withRedirect === true) {
        Turbo.visit("/users/sign_in")
      }
    }
  }
}
