import { Controller } from "@hotwired/stimulus"

// Organigramme mittels Google orgchart zeichnen: https://developers.google.com/chart/interactive/docs/gallery/orgchart
// Organigramme Content kann entweder direkt im HTML als data-Attribut oder über eine JSON-Datei geladen werden
// Connects to data-controller="organigram"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array,
    dataUrl: String,
    dataStructure: Array,
  }

  initialize() {
    if (document.querySelector('script[src="https://www.gstatic.com/charts/loader.js"]')) return

    const script = document.createElement("script")
    script.src = "https://www.gstatic.com/charts/loader.js"
    script.onload = () => {
      google.charts.load("current", { packages: ["orgchart"], callback: this.drawChart() })
    }
    document.head.appendChild(script)
  }

  connect() {
    this.chartElement = this.hasChartTarget ? this.chartTarget : this.element
  }

  disconnect() {
    if (this.chart) {
      this.chart.clearChart()
    }
  }

  async drawChart() {
    if (typeof google === "undefined" || google.visualization === undefined) {
      // Aus irgendeinem Grund ist `google.visualization` nicht sofort verfügbar, obwohl wir von `google.charts`
      // ein loaded callback bekommen.... Daher warten wir in diesem Fall kurz. Nicht schön, aber was solls
      await new Promise((resolve) => setTimeout(resolve, 500))
    }
    if (typeof google === "undefined" || google.visualization === undefined) return
    google.charts.load("current", { packages: ["orgchart"] })

    const organigramData = this.hasDataValue ? this.dataValue : await this.fetchOrganigramData()
    const dataTable = new google.visualization.DataTable()
    this.dataStructureValue.forEach((structure) => {
      dataTable.addColumn(structure.type, structure.label)
    })
    dataTable.addRows(organigramData)

    this.chart = new google.visualization.OrgChart(this.chartElement)
    this.chart.draw(dataTable, { allowHtml: true })
  }

  async fetchOrganigramData() {
    const response = await fetch(this.DataUrlValue)
    const data = await response.json()
    return data.organigram
  }
}
