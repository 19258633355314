import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import MobileSwipeMenu from "mobile-swipe-menu"

// Connects to data-controller="native--nav-bar"
export default class extends Controller {
  static targets = ["navBar"]

  connect() {
    this.opened = false

    // Nav-Bar "swipebar" machen
    this.mobileMenu = new MobileSwipeMenu(this.navBarTarget, {
      mode: "left",
      width: window.innerWidth / 1.3,
      enableBodyHook: false,
      events: {
        opened: () => {
          this.opened = true
        },
        closed: () => {
          this.opened = false
        },
      },
    })

    // Wenn ausserhalb der Nav-Bar geklickt wird, wird die Nav-Bar geschlossen
    useClickOutside(this, { element: this.navBarTarget, onlyVisible: true })
  }

  openLink(event) {
    this.navBarTarget.style.display = "none"

    // Wenn der Link zu früh aufgerufen wird, ist die Nav-Bar noch nicht geschlossen wenn MobileApp ein screenshot macht.
    // Dies führt beim zurücknavigieren zu einem "flackern".
    // Hack: Durch das setTimeout sicherstellen, dass die Nav-Bar geschlossen ist, bevor der Link aufgerufen wird.
    setTimeout(() => {
      Turbo.visit(event.target.closest(".nav-link").dataset.href)
    }, 40)
  }

  toggle() {
    this.mobileMenu.toggleMenu()
  }

  open() {
    this.mobileMenu.openMenu()
  }

  close() {
    this.mobileMenu.closeMenu()
  }

  morphElement(event) {
    // Die Nav-Bar darf nicht gemorpht werden, da sonst die inline-Styles überschrieben werden
    // Deswegen verhindern wir das Morphing hier
    event.preventDefault()
  }

  clickOutside(event) {
    if (this.opened) {
      event.preventDefault()
      event.stopPropagation()

      this.close()
    }
  }
}
