import { addNewTemplateInstance, listenerForDeletedButtons } from "../global/dynamic_form"

$(document).on("turbo:load", function () {
  if ($("#device-type-form").length > 0) {
    listenerForDeletedButtons()

    $("#new-device-type-feature").on("click", function () {
      let current_time = new Date().getTime()
      addNewTemplateInstance(current_time, "device-type-features")
      listenerForDeletedButtons()

      showOrHideFeatureUsageTitle("features")
    })

    $("#new-device-type-usage").on("click", function () {
      let current_time = new Date().getTime()
      addNewTemplateInstance(current_time, "device-type-usages")
      listenerForDeletedButtons()

      showOrHideFeatureUsageTitle("usages")
    })
  }
})

function showOrHideFeatureUsageTitle(featureOrUsages) {
  $(`#device-type-has-${featureOrUsages}`).removeClass("d-none")
  $(`#device-type-has-no-${featureOrUsages}`).addClass("d-none")
}
