import { post } from "@rails/request.js"

// Der Zweck dieses BLEScanner's ist es mit den BLE Daten der nativen App umzugehen.
// Wir möchten z.B. nicht jedes empfangene Gerät direkt an den Server senden, da wir sonst den unnötig belasten würden.
// Stattdessen sammeln wir die Resultate in memory und senden sie in einem bestimmten Intervall an den Server.
export default class BLEScanner {
  static beacons = new Map()
  static devices = new Map()
  static sendInterval = null

  constructor(intervalInMs = 1000) {
    this.intervalInMs = intervalInMs
  }

  startBLEScanning = async (payload) => {
    this._stopAndClear()
    this._startInterval()

    window.dispatchEvent(new CustomEvent("mobileapp:did-start-ble-scanning"))
  }

  stopBLEScanning = async (payload) => {
    this._stopAndClear()

    const { error, params } = JSON.parse(payload)

    window.dispatchEvent(new CustomEvent("mobileapp:did-stop-ble-scanning"))

    // Mögliche Fehlermeldungen: "", "BLE is powered off"
    if (error === "BLE is powered off") {
      window.dispatchEvent(new CustomEvent("mobileapp:ble-powered-off"))
    }
  }

  didReceiveScanUpdate(view, payload) {
    switch (view) {
      case "devices":
        const { devices } = JSON.parse(payload)
        devices.forEach((newDevice) => {
          this.devices.set(newDevice.code, newDevice)
        })
        break
      case "beacons":
        const { beacons } = JSON.parse(payload)
        beacons.forEach((newBeacon) => {
          this.beacons.set(newBeacon.code, newBeacon)
        })
        break
    }
  }

  didReceiveNearestBeaconScanUpdate = async (payload, params) => {
    // Gefundener Beacon mit Event verknüpfen
    const { nearestBeacon, beacons } = JSON.parse(payload)
    const { event_id, confirmation_step } = JSON.parse(params)
    const beaconCode = nearestBeacon.code

    if (beaconCode && event_id && confirmation_step !== undefined) {
      post(`/events/${event_id}/confirmation_location`, { body: { confirmation_step: confirmation_step, beacon_code: beaconCode }, responseKind: "json" })
    }

    // Sämtliche Beacons an den Server senden (um last time seen und Batteriesstand zu aktualisieren)
    post("/api/mobile/v1/update_beacons", { body: { beacons: beacons } })
  }

  _startInterval() {
    if (!this.sendInterval) {
      this.sendInterval = setInterval(this._sendDataToServer.bind(this), this.intervalInMs)
    }
  }

  _stopInterval() {
    clearInterval(this.sendInterval)
    this.sendInterval = null
  }

  _clearBuffer() {
    this.beacons = new Map()
    this.devices = new Map()
  }

  _stopAndClear() {
    this._stopInterval()
    this._clearBuffer()
  }

  _sendDataToServer() {
    // Wenn devices oder beacons vorhanden sind, sende sie an den Server
    const deviceArray = Array.from(this.devices?.values())
    if (deviceArray.length > 0) {
      post("/mobile_app/device_scanner", { body: { devices: deviceArray }, responseKind: "turbo-stream" })
    }

    const beaconArray = Array.from(this.beacons?.values())
    if (beaconArray.length > 0) {
      post("/mobile_app/beacon_scanner", { body: { beacons: beaconArray }, responseKind: "turbo-stream" })
    }
  }
}
