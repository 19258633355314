import { post } from "@rails/request.js"
import { mobileAppPlatform, mobileAppVersion, parseJSON } from "../../global/utils"

export const saveFCMToken = async (payload) => {
  const parsedJSON = JSON.parse(payload)
  const fcmToken = parsedJSON["token"]
  const uniqueDeviceId = parsedJSON["uniqueDeviceId"]
  const previousFCMToken = localStorage.getItem("currentFCMToken")

  // Nur weitermachen, wenn wir den FCM Token noch nicht kennen
  if (previousFCMToken === fcmToken) return

  const body = {
    token: fcmToken,
    device_type: mobileAppPlatform,
    unique_device_id: uniqueDeviceId,
    app_version: mobileAppVersion,
  }
  const response = await post("/push_notification_registrations", { body: body })

  if (response.ok) {
    localStorage.setItem("currentFCMToken", fcmToken)
    console.log("FCM Token wurde an den Server gesendet und gespeichert")
  } else {
    console.log("FCM Token wurde an den Server gesendet aber Server sagt Nein")
  }
}

export const removeFCMTokenCache = () => {
  localStorage.removeItem("currentFCMToken")
}

export const receivedPushNotification = (payload) => {
  const data = parseJSON(payload)
  if (!data) return

  // In bestimmten Momenten wollen wir die aktuelle Seite neu laden
  // z.B. in Dashboard um sicherzustellen, dass die neuesten Informationen angezeigt werden,
  // unabhängig ob die Websocket-Verbindung eingebrochen ist oder nicht.
  const { event_id } = JSON.parse(payload)
  const currentPath = window.location.pathname
  const isCurrentlyOnDashboard = currentPath.includes("dashboard") || currentPath == "/"
  const isCurrentlyOnEventPage = currentPath.includes("events") && currentPath.includes(event_id)

  if (isCurrentlyOnDashboard || isCurrentlyOnEventPage) {
    window.dispatchEvent(new Event("mobileapp:maybe-refresh"))
  }
}
