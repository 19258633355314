import { animateCountUp } from "../global/count_up"
import { createChart } from "../global/chart_functions"

$(document).on("turbo:load", async function () {
  if (document.getElementById("dashboard-show")) {
    // CallRule reset
    document.getElementById("dashboard-show").addEventListener("click", function (event) {
      if (event.target.classList.contains("reset-temp-call-rule")) {
        let tempCallRule = document.getElementById("customer_temporary_call_forward_to_customer_id")
        tempCallRule.value = ""
        tempCallRule.closest("form").requestSubmit()
      }
    })

    // Dashboard Charts
    // JSON Daten holen
    const dashboardStatisticsAreEmpty = document.getElementById("dashboard-statistics").style.display == "none"
    if (document.getElementById("dashboard-statistics") && dashboardStatisticsAreEmpty) {
      async function dashboardChartData(whichData) {
        const response = await fetch(`/dashboard.json?which_data=${whichData}`)
        const data = await response.json()
        return data
      }

      // Chart Optionen definieren
      function dashboardChartOptions() {
        return {
          locale: "de-DE",
          pointRadius: 0,
          hitRadius: 20,
          hoverRadius: 7,
          scales: {
            x: {
              adapters: {
                date: {
                  locale: "de-DE",
                },
              },
              type: "time",
              time: {
                unit: "day",
                displayFormats: {
                  day: "ccc",
                },
              },
              grid: {
                display: false,
              },
            },
            yAxes: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: function (context) {
                  let label = context.formattedValue || ""
                  return label + " Ereignisse"
                },
                title: function (context) {
                  const d = new Date(context[0].parsed.x)
                  const formattedDate = d.toLocaleDateString("de-CH", { year: "numeric", month: "2-digit", day: "2-digit" })
                  return formattedDate
                },
              },
            },
          },
        }
      }

      // Chart Daten zusammenstellen
      function constructDashboardChartData(data) {
        return {
          labels: data.x,
          datasets: [
            {
              animation: false,
              label: false,
              data: data.y,
              lineTension: 0.2,
              backgroundColor: ["rgba(54, 162, 235, 0.2)"],
              borderColor: ["rgba(54, 162, 235, 1)"],
              borderWidth: 1,
              fill: true,
              gradient: true,
            },
          ],
        }
      }

      // Charts die angezeigt werden sollen
      const dashboardChartsToDisplay = [{ id: "events_from_the_last_days", type: "line" }]

      let allDashboardCharts = []

      // Event Zahlen darstellen
      function displayDashboardEventNumbers(newAmounts) {
        for (let i = 0; i < displayedDashboardEventNumbers.length; i++) {
          const idFromNumber = displayedDashboardEventNumbers[i][0].id
          const newAmount = String(newAmounts[idFromNumber]).replace(/(.)(?=(\d{3})+$)/g, "$1'")
          displayedDashboardEventNumbers[i].find("h3").html(newAmount)
          animateCountUp(displayedDashboardEventNumbers[i].find("h3")[0]) // CountUp Animation
        }
      }

      const displayedDashboardEventNumbers = [$("#dashboard_events_amount"), $("#dashboard_events_avg_per_day"), $("#dashboard_events_avg_per_hour")]
      const dashboardEventNumbers = await dashboardChartData("event_numbers")

      // Wenn es events gab, Event Zahlen und chart anzeigen.
      if (dashboardEventNumbers.dashboard_events_amount > 0 && dashboardEventNumbers.dashboard_events_amount != dashboardEventNumbers.dashboard_events_avg_per_day) {
        displayDashboardEventNumbers(dashboardEventNumbers, true)
        dashboardChartsToDisplay.forEach(async function (chart) {
          const chartData = await dashboardChartData("dashboard_charts")
          const chartJSData = constructDashboardChartData(chartData[chart.id])
          const chartJSOptions = dashboardChartOptions()

          const createdChart = await createChart(chart.type, chart.id, { data: chartJSData, options: chartJSOptions })
          allDashboardCharts.push(createdChart)
        })
        $("#dashboard-statistics").fadeIn(600)
      } else {
        $("#dashboard-statistics").fadeOut()
      }
    }
  }
})
