import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click-effect"
export default class extends Controller {
  connect() {
    this.boundDefaultClickEffectStart = this.defaultClickEffectStart.bind(this)
    this.boundDefaultClickEffectEnd = this.defaultClickEffectEnd.bind(this)

    this.element.addEventListener("touchstart", this.boundDefaultClickEffectStart)
    this.element.addEventListener("mousedown", this.boundDefaultClickEffectStart)

    // Touchend und Mouseup müssen auf dem Window oder Dokument registriert werden,
    // da der User den Finger/Mauszeiger auch ausserhalb des Elements loslassen könnte.
    window.addEventListener("touchend", this.boundDefaultClickEffectEnd)
    window.addEventListener("mouseup", this.boundDefaultClickEffectEnd)
  }

  disconnect() {
    this.element.removeEventListener("touchstart", this.boundDefaultClickEffectStart)
    this.element.removeEventListener("mousedown", this.boundDefaultClickEffectStart)
    window.removeEventListener("touchend", this.boundDefaultClickEffectEnd)
    window.removeEventListener("mouseup", this.boundDefaultClickEffectEnd)
  }

  defaultClickEffectStart() {
    this.element.style.filter = "brightness(0.9)"
  }

  defaultClickEffectEnd() {
    this.element.style.filter = "brightness(1)"
  }
}
