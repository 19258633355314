import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="counter"
export default class extends Controller {
  static values = {
    counter: { type: Number, default: 0 },
  }

  maybeTriggerMiniProfiler() {
    this.counterValue += 1
    if (this.counterValue % 5 == 0) {
      const url = new URL(window.location.href)
      url.searchParams.set("mini_profiler", "toggle")
      Turbo.visit(url.href, { action: "replace" })
    }

    this.resetCounterIn(1000)
  }

  maybeTriggerENVChange() {
    this.counterValue += 1
    if (this.counterValue % 5 == 0) {
      Turbo.visit("/mobile_app/env_change")
    }

    this.resetCounterIn(1000)
  }

  resetCounterIn(ms) {
    // Wenn resetCounterIn innerhalb der angegebenen Zeit nochmal aufgerufen wird,
    // wird der alte Timeout gelöscht und ein neuer gesetzt. Quasi ein debounce.
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    // Timeout setzen, um den Counter zurückzusetzen
    this.timeoutId = setTimeout(() => {
      this.counterValue = 0
    }, ms)
  }
}
