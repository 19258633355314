document.addEventListener("turbo:load", () => {
  // Automatisch Sidebar öffnen oder schliessen
  if (sessionStorage.getItem("sidenavCollapsed") === "false") {
    document.body.classList.remove("sidenav-toggled")
  } else {
    document.querySelectorAll(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").forEach((el) => {
      el.classList.add("collapse")
    })
  }

  // Öffnen / Schliessen der Sidebar abfangen
  document.getElementById("sidenavToggle")?.addEventListener("click", (e) => {
    e.preventDefault()
    const isCollapsed = sessionStorage.getItem("sidenavCollapsed") === "true"
    sessionStorage.setItem("sidenavCollapsed", (!isCollapsed).toString())
    document.body.classList.toggle("sidenav-toggled")

    document.querySelectorAll(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").forEach((el) => {
      el.classList.remove("show")
      el.classList.add("collapse")
    })
  })
})
