import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="downloadable-file"
export default class extends Controller {
  static targets = ["container", "file"]

  connect() {
    this.showOrHideContainer()
  }

  fileTargetConnected() {
    this.showContainer()
  }

  fileTargetDisconnected() {
    this.showOrHideContainer()
  }

  showOrHideContainer() {
    this.fileTargets.length === 0 ? this.hideContainer() : this.showContainer()
  }

  showContainer() {
    this.containerTarget.classList.remove("d-none")
  }

  hideContainer() {
    this.containerTarget.classList.add("d-none")
  }
}
