// Diese Bridge-Klasse dient als Schnittstelle zwischen der Turbo Native App und der Web-App.
// Dokumentation:
// https://github.com/hotwired/turbo-android/blob/main/docs/ADVANCED-OPTIONS.md#native---javascript-integration
// https://masilotti.com/turbo-ios/the-javascript-bridge

import { post } from "@rails/request.js"
import { saveFCMToken, receivedPushNotification } from "./native_actions/push_notifications"
import { receivedPermissionState } from "./native_actions/mobile_app_infos"
import BLEScanner from "./native_actions/ble_scanner.js"

export default class Bridge {
  static bleScanner = new BLEScanner()

  // Nachricht von der Turbo Native App empfangen
  static postNativeToJS(action, payload, params = "") {
    console.debug(`#postNativeToJS action: ${action} payload: ${payload} params: ${params}`)

    switch (action) {
      case "didPressMenu":
        window.dispatchEvent(new CustomEvent("mobileapp:toggle-nav-bar"))
        break
      case "didReceiveFCMToken":
        saveFCMToken(payload)
        break
      case "didReceiveDeviceScanUpdate":
        // payload => {"devices":"[{"code": "000BFA", "name": "Oxocare000BFA", "rssi": "-41", "runaway_protection_active": "false"}]","params":"{}"}
        this.bleScanner.didReceiveScanUpdate("devices", payload)
        break
      case "didReceiveBeaconScanUpdate":
        // payload => {"beacons":[{"code":"52DE09","name":"AC233F52DE09","rssi":-88,"battery_level":null}],"params":"{}"}
        this.bleScanner.didReceiveScanUpdate("beacons", payload)
        break
      case "didReceiveNearestBeaconScanUpdate":
        // payload: {"nearestBeacon":{"code":"7FAB30","name":"AC233F7FAB30","rssi":-89,"battery_level":null},"beacons":[{"code":"7FAB30","name":"AC233F7FAB30","rssi":-89,"battery_level":null}], "params":"{"event_id":2781482,"confirmation_step":1}"}}
        this.bleScanner.didReceiveNearestBeaconScanUpdate(payload, params)
        break
      case "didStartBLEScanning":
        // payload => {type: "devices"}
        // Mögliche Typen: "devices", "beacons", "nearest_beacon"
        this.bleScanner.startBLEScanning(payload)
        break
      case "didStopBLEScanning":
        // payload => {error: "BLE is powered off"}
        this.bleScanner.stopBLEScanning(payload)
        break
      case "didReceiveBLEState":
        // payload => {"bleEnabled":true,"bleState":12,"permissionState":"{android.permission.ACCESS_FINE_LOCATION=false, android.permission.BLUETOOTH_SCAN=false}"}
        // console.log('didReceiveBLEState', payload);
        break
      case "didReceiveNotification":
        // payload => {"url":"http://192.168.42.172:3000/events/2781658","body":"Armband\n Pflegeruf / Oxon AG","event_id":2781658,"title":"Frau Blau / Aufenthaltsraum (demo)","is_silent":"false","current_step_index":-1,"active_event_count":1}
        receivedPushNotification(payload)
        break
      case "didReceiveDeviceInfo":
        // payload => {"ringer_mode":"normal","volume":"43%"}
        break
      case "didReceivePermissions":
        // payload => {"deniedPermissions":["android.permission.CAMERA","android.permission.POST_NOTIFICATIONS"]}
        receivedPermissionState(payload)
        break
      case "didStartNFCScanning":
        // payload => {}
        break
      case "didStopNFCScanning":
        // payload => {}
        break
      case "didReceiveNFCScan":
        // DEPRECATED - Neu soll didReceiveNFCScanUpdate verwendet werden.
        // Marcel Karl von GHN verwendet aber noch ein Prototyp welche auf diese JS Funktion zeigt.
        // payload => {"tagID":"0489A2624F1290"}
        alert("Folgende NFC ID wurde gefunden: " + JSON.parse(payload).tagID)
        break
      case "didReceiveNFCScanUpdate":
        // payload => {"tagID":"0489A2624F1290"}
        window.bridge.postJSToNative("stopNFCScan")
        alert("Folgende NFC ID wurde gefunden: " + JSON.parse(payload).tagID)
        break
      case "didEnterForeground":
        window.dispatchEvent(new Event("mobileapp:app-opened"))
        break
      case "didEnterBackground":
        // window.dispatchEvent(new Event("mobileapp:app-closed"));
        break
      case "didTerminate":
        // window.dispatchEvent(new Event("mobileapp:app-terminated"));
        break
      default:
        console.log("#postNativeToJS unknown action " + action)
    }
  }

  // Nachricht an die Turbo Native App senden
  static postJSToNative(action, payload = {}) {
    console.debug("#postJSToNative action: " + action + " payload: " + JSON.stringify(payload))

    // Android
    window.nativeApp?.postMessage(action, JSON.stringify(payload))

    // iOS
    window.webkit?.messageHandlers?.nativeApp?.postMessage({ action: action, payload: payload })
  }
}
