export const pageIsTurboPreview = () => {
  return document.documentElement.hasAttribute("data-turbo-preview")
}

export const getMetaValue = (name) => {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}

export const debounce = (fn, delay) => {
  let timeoutId = null

  return (...args) => {
    const callback = () => fn.apply(this, args)
    clearTimeout(timeoutId)
    timeoutId = setTimeout(callback, delay)
  }
}

export const parseJSON = (data) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    console.error("Invalid JSON:", data)
    return null
  }
}

const { userAgent } = window.navigator
export const isIosApp = /Turbo Native iOS/.test(userAgent)
export const isAndroidApp = /Turbo Native Android/.test(userAgent)
export const isMobileApp = isIosApp || isAndroidApp
export const mobileAppPlatform = isMobileApp ? (isIosApp ? "ios" : "android") : null
export const mobileAppVersion = isMobileApp ? (userAgent.match(/app_version: ([^;]+)/) || [])[1] : null // ([^;]+) => alle Zeichen ausser Semikolon
export const mobileAppUniqueDeviceId = isMobileApp ? (userAgent.match(/unique_device_id: ([^;]+)/) || [])[1] : null // ([^;]+) => alle Zeichen ausser Semikolon

export default {
  pageIsTurboPreview,
  getMetaValue,
  debounce,
  parseJSON,
  isIosApp,
  isAndroidApp,
  isMobileApp,
  mobileAppPlatform,
  mobileAppVersion,
  mobileAppUniqueDeviceId,
}
