import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="switch-user-permission"
export default class extends Controller {
  static targets = ["checkbox"]

  toggle(event) {
    event.stopPropagation() // Um dropdown nicht zu schließen
    event.preventDefault() // Um checkbox nicht zu togglen (wird manuell gemacht)

    // setTimeout wird benötigt wegen `preventDefault``
    // ohne setTimeout könnten wir den Checkbox Wert nicht ändern
    setTimeout(() => {
      this.checkboxTarget.checked = !this.checkboxTarget.checked
      this.requestSelectedPermission(this.checkboxTarget.id, this.checkboxTarget.checked)
    })
  }

  async requestSelectedPermission(permissionName, checkBoxState) {
    const userId = document.querySelector('meta[name="current-user-id"]').content
    const customerId = document.querySelector('meta[name="current-customer-id"]').content
    const body = `{
      "${permissionName}": ${checkBoxState},
      "switched_permission_view": true,
      "customer_id": ${customerId}
    }`

    await patch(`/users/${userId}`, {
      body: body,
      responseKind: "json",
    })
  }
}
