import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    $(".select-select2").select2({
      theme: "bootstrap4",
      language: "de",
      minimumResultsForSearch: 10,
    })
  }
}
