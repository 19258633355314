import { Controller } from "@hotwired/stimulus"
// Dieser Controller kann verwendet werden, um die Websocket Verbindung
// zu überwachen und den Status anzuzeigen.

// Connects to data-controller="ws-status"
export default class extends Controller {
  static targets = ["streamElement", "onlineIndicator", "offlineIndicator"]
  static values = {
    autoStart: { type: Boolean, default: true },
    autoRefresh: { type: Boolean, default: true },
    interval: { type: Number, default: 1000 },
  }

  connect() {
    if (this.autoStartValue) {
      this.start()
    }
  }

  disconnect() {
    this.stop()
  }

  start() {
    if (!this.hasStreamElementTarget) return
    if (!this.hasOnlineIndicatorTarget) return

    this.interval = setInterval(() => {
      if (this.isOnline) {
        const wasOffline = this.onlineIndicatorTarget.classList.contains("d-none")

        this.onlineIndicatorTarget.classList.remove("d-none")
        this.offlineIndicatorTarget.classList.add("d-none")

        if (wasOffline && this.autoRefreshValue) {
          Turbo.session.refresh(location.href)
        }
      } else {
        this.onlineIndicatorTarget.classList.add("d-none")
        this.offlineIndicatorTarget.classList.remove("d-none")
      }
    }, this.intervalValue)
  }

  stop() {
    clearInterval(this.interval)
  }

  get isOnline() {
    return this.streamElementTargets.every((element) => element.hasAttribute("connected"))
  }
}
