import { Controller } from "@hotwired/stimulus"
import IconPicker from "vanilla-icon-picker"

// Connects to data-controller="icon-picker"
export default class extends Controller {
  static targets = ["inputField", "iconPreview", "iconPrefixField", "iconNameField"]

  connect() {
    const iconPicker = new IconPicker(this.inputFieldTarget, {
      theme: "bootstrap-5",
      iconSource: ["FontAwesome Solid 6", "FontAwesome Regular 6"],
      closeOnSelect: true,
    })

    iconPicker.on("select", (icon) => {
      if (icon.value.indexOf("fas ") !== -1) {
        this.iconPrefixFieldTarget.value = "fas"
      } else if (icon.value.indexOf("far ") !== -1) {
        this.iconPrefixFieldTarget.value = "far"
      } else if (icon.value.indexOf("fa ") !== -1) {
        this.iconPrefixFieldTarget.value = "fa"
      }

      this.iconNameFieldTarget.value = icon.name
      this.iconPreviewTarget.className = icon.value
    })
  }
}
