import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="escalation-settings"
export default class extends Controller {
  static targets = ["checkbox", "inputField"]

  toogleInputFields() {
    const atLeastOneCheckboxIsActive = this.checkboxTargets.some((checkbox) => checkbox.checked)
    this.inputFieldTargets.forEach((inputField, i) => {
      if (atLeastOneCheckboxIsActive) {
        inputField.readOnly = false
      } else {
        inputField.value = ""
        inputField.readOnly = true
      }
    })
  }
}
